// plugins/hubspot.ts

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.provide('hubspot', {
    async searchContact(email: string) {
      const { data, error } = await useFetch('/api/hubspot/search', {
        method: 'POST',
        body: { email },
      })
      if (error.value) {
        throw new Error(error.value)
      }

      return data
    },
    async updateContact(contactId: string, properties: Record<string, any>) {
      const { data, error } = await useFetch(
        `/api/hubspot/update/${contactId}`,
        {
          method: 'PATCH',
          body: { properties },
        }
      )
      if (error.value) {
        throw new Error(error.value)
      }
      return data
    },
    async createContact(properties: Record<string, any>) {
      const { data, error } = await useFetch('/api/hubspot/create', {
        method: 'POST',
        body: { properties },
      })
      if (error.value) {
        throw new Error(error.value)
      }
      return data
    },
    async getUserIp() {
      const { data, error } = await useFetch('/api/get-user-ip')
      if (error.value) {
        throw new Error(error.value)
      }
      return data      
    },
  })
})
