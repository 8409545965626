export default defineNuxtRouteMiddleware((to, from) => {
  const query = { ...to.query }

  const keys = [
    'pageid',
    'term',
    'f',
    'scriptType',
    'f_extId',
    'f_hierarchy',
    'f_writer',
    'f_author',
    'f_genre',
    'colId',
    'f_title',
    'type',
  ]

  if (from.hash) {
    for (const key of keys) {
      if (!to.query[key] && from.query[key]) {
        query[key] = from.query[key]
      }
    }
  }

  if (Object.keys(query).length !== Object.keys(to.query).length) {
    return navigateTo({
      path: to.path,
      query,
    })
  }
})
