<template>
  <nav class="app-navbar">
    <div class="app-navbar__wrapper">
      <!-- Left elements -->
      <div class="app-navbar__block">
        <!-- Brand Logo -->
        <div class="app-navbar__brand">
          <NuxtLink :to="localePath('/')">
            <img
              class="app-navbar__brand__img app-navbar__brand__img--original"
              loading="lazy"
              src="~/assets/images/Transkribus.svg"
            />
            <img
              class="app-navbar__brand__img app-navbar__brand__img--models"
              loading="lazy"
              src="~/assets/images/Transkribus-models.svg"
            />
            <img
              class="app-navbar__brand__img app-navbar__brand__img--sites"
              loading="lazy"
              src="~/assets/images/Transkribus-sites.svg"
            />
          </NuxtLink>
        </div>
      </div>
      <div class="app-navbar__burger-btn">
        <div class="border-r">
          <BaseButton
            as-label
            small
            :type="'text'"
            :label="'Jobs'"
            :for="'Jobs'"
            @click="emitSlideOver()"
            class="!px-3"
          ></BaseButton>
        </div>
        <div class="pl-3 z-20">
          <label class="text-2xl cursor-pointer" for="nav-drawer">
            <i class="mdi mdi-menu" v-if="!drawerOpen"></i>
            <i class="mdi mdi-close" v-else></i>
          </label>
        </div>
      </div>
      <!-- Right elements -->
      <div class="app-navbar__block app-navbar__block--right">
        <!-- Desktop: Menu -->
        <div class="app-navbar__menu">
          <ul class="app-navbar__list">
            <li
              class="app-navbar__item active"
              :class="{
                'app-animation--loading app-animation--loading--primary':
                  loadingIndex === index,
              }"
              v-for="(navbarItem, index) in navbarItems"
            >
              <NuxtLink
                @click="handleClick(index, navbarItem, $event)"
                :to="localePath(navbarItem.path)"
                class="app-navbar__link"
                :class="{ active: isActive(navbarItem.path) }"
              >
                <!-- nav icon-->
                <span class="material-symbols-outlined mr-1.5">{{
                  navbarItem.icon
                }}</span>
                <!-- nav text -->
                <span> {{ navbarItem.name }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>

        <!-- User: loggedin -->
        <div
          v-if="userProfile"
          class="app-navbar__block__inner app-navbar__block__inner--with-line hidden"
        >
          <BaseButton
            as-label
            small
            :type="'text'"
            :label="'Jobs'"
            :for="'Jobs'"
            @click="emitSlideOver()"
            class="!px-3"
          ></BaseButton>
          <!-- Dropdown -->
          <div class="app-navbar__dropdown">
            <label tabindex="0" class="app-navbar__dropdown__btn">
              <div class="app-navbar__dropdown__btn__wrapper">
                <i class="app-navbar__dropdown__btn__icon mdi mdi-account"></i>
              </div>
            </label>
            <ul
              tabindex="0"
              class="app-navbar__dropdown__menu dropdown-content bg-white"
            >
              <li
                class="app-navbar__dropdown__item app-navbar__dropdown__item__title"
              >
                <span class="app-navbar__dropdown__item__title__text">
                  {{ $t('Signed in as') }}
                  <strong> {{ userProfile.Email }}</strong></span
                >
              </li>
              <li
                class="app-navbar__dropdown__item app-navbar__dropdown__item--collapse"
              >
                <input type="checkbox" />
                <div class="collapse-title">
                  <i class="mdi mdi-earth"></i>
                  <span>{{ $t('Language') }}</span>
                </div>
                <div class="collapse-content">
                  <ul>
                    <li
                      v-for="(locale, index) in availableLocales"
                      :key="index"
                      class="app-navbar__item focus:bg-transparent"
                    >
                      <span @click="setAndStoreLocale(locale.code)">
                        <img
                          class="h-5 w-5"
                          loading="lazy"
                          :src="`/images/flags/${locale.label.toLowerCase()}.svg`"
                        />
                        <span class="is-uppercase">{{ locale.label }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
              <hr class="w-full border-gray-50 my-1" />
              <li class="app-navbar__dropdown__item">
                <a
                  :href="
                    isAlpha
                      ? 'https://account.readcoop.eu/authTesting/realms/readcoop/account'
                      : 'https://account.readcoop.eu/auth/realms/readcoop/account'
                  "
                  target="_blank"
                >
                  <i class="mdi mdi-cog"></i>
                  <span>{{ $t('My Account') }}</span>
                </a>
              </li>
              <li class="app-navbar__dropdown__item">
                <NuxtLink
                  :to="
                    localePath({
                      name: 'usage',
                    })
                  "
                >
                  <i class="mdi mdi-chart-bar"></i>
                  <span>{{ $t('Usage') }}</span>
                </NuxtLink>
              </li>
              <li
                v-if="userProfile.Individual"
                class="app-navbar__dropdown__item"
              >
                <BaseButton
                  :type="'upgrade'"
                  :label="$t('Upgrade to Scholar')"
                  :icon="'arrow-up-bold-circle'"
                  iconRight
                  small
                  @click="openPremium()"
                ></BaseButton>
              </li>
              <hr class="w-full border-gray-50 my-1" />
              <li class="app-navbar__dropdown__item">
                <NuxtLink to="https://help.transkribus.org/" target="_blank">
                  <i class="mdi mdi-help-circle"></i>
                  <span>{{ $t('Help Center') }}</span>
                </NuxtLink>
              </li>
              <li class="app-navbar__dropdown__item">
                <NuxtLink
                  to="https://help.transkribus.org/kb-tickets/new"
                  target="_blank"
                >
                  <i class="mdi mdi-face-agent"></i>
                  <span>{{ $t('Request Support') }}</span>
                </NuxtLink>
              </li>
              <li class="app-navbar__dropdown__item" id="sentry-bug">
                <a>
                  <i class="mdi mdi-bug"></i>
                  <span>{{ $t('Report Issue') }}</span>
                </a>
              </li>
              <li class="app-navbar__dropdown__item">
                <NuxtLink
                  :to="
                    localePath({
                      name: 'changelog',
                    })
                  "
                >
                  <i class="mdi mdi-creation"></i>
                  <span>{{ $t('Whats new') }}</span>
                </NuxtLink>
              </li>
              <hr class="w-full border-gray-50 my-1" />
              <li class="app-navbar__dropdown__item">
                <a @click="showPrivacySettings">
                  <i class="mdi mdi-cookie"></i>
                  <span>{{ $t('Privacy Settings') }}</span>
                </a>
              </li>
              <li class="app-navbar__dropdown__item">
                <NuxtLink to="https://legal.transkribus.org/" target="_blank">
                  <i class="mdi mdi-scale-balance"></i>
                  <span>{{ $t('Legal Information') }}</span>
                </NuxtLink>
              </li>
              <li
                v-if="userProfile?.IsAdmin"
                class="app-navbar__dropdown__item"
              >
                <a @click="togglePosthog()">
                  <i
                    :class="
                      posthogEnabled
                        ? 'mdi mdi-eye-outline'
                        : 'mdi mdi-eye-off-outline'
                    "
                    class="app-navbar__dropdown__icon"
                    aria-hidden="true"
                  ></i>
                  <span v-if="posthogEnabled">{{ $t('Disable posthog') }}</span>
                  <span v-else>{{ $t('Enable posthog') }}</span>
                </a>
              </li>
              <hr class="w-full border-gray-50 my-1" />
              <li class="app-navbar__dropdown__item">
                <a @click="logout">
                  <i class="mdi mdi-logout"></i>
                  <span>{{ $t('Logout') }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Mobile: Menu -->
      <div
        class="app-navbar__drawer drawer drawer-end"
        :class="drawerOpen ? 'pointer-events-auto' : 'pointer-events-none'"
      >
        <input
          id="nav-drawer"
          type="checkbox"
          class="app-navbar__drawer__toggle"
          v-model="drawerOpen"
        />
        <div class="drawer-side">
          <label for="nav-drawer" class="drawer-overlay"></label>
          <ul
            class="app-navbar__drawer__menu app-navbar__drawer__menu--mobile rounded"
          >
            <li class="app-navbar__drawer__menu__item">
              <span class="!pb-0">
                {{ $t('Signed in as') }}
              </span>
              <strong> {{ userProfile.Email }}</strong>
            </li>
            <hr class="w-full border-gray-50 my-1" />
            <li
              class="app-navbar__drawer__menu__item"
              v-for="(navbarItem, index) in navbarItems"
            >
              <NuxtLink
                @click="handleClick(index, navbarItem, $event)"
                :to="localePath(navbarItem.path)"
                class="app-navbar__link"
                :class="{ active: isActive(navbarItem.path) }"
              >
                <!-- nav icon-->
                <span class="material-symbols-outlined mr-1.5">{{
                  navbarItem.icon
                }}</span>
                <!-- nav text -->
                <span> {{ navbarItem.name }}</span>
              </NuxtLink>
            </li>
            <hr class="w-full border-gray-50 my-1" />
            <li class="app-navbar__drawer__menu__item">
              <a
                :href="
                  isAlpha
                    ? 'https://account.readcoop.eu/authTesting/realms/readcoop/account'
                    : 'https://account.readcoop.eu/auth/realms/readcoop/account'
                "
                target="_blank"
              >
                <i class="mdi mdi-cog"></i>
                <span>{{ $t('My Account') }}</span>
              </a>
            </li>
            <li class="app-navbar__drawer__menu__item">
              <NuxtLink
                :to="
                  localePath({
                    name: 'usage',
                  })
                "
              >
                <i class="mdi mdi-chart-bar"></i>
                <span>{{ $t('Usage') }}</span>
              </NuxtLink>
            </li>
            <li
              v-if="userProfile?.Individual"
              class="app-navbar__drawer__menu__item"
            >
              <BaseButton
                :type="'upgrade'"
                :label="$t('Upgrade to Scholar')"
                :icon="'arrow-up-bold-circle'"
                iconRight
                small
                @click="openPremium()"
              ></BaseButton>
            </li>
            <hr class="w-full border-gray-50 my-1" />
            <li class="app-navbar__drawer__menu__item">
              <NuxtLink to="https://help.transkribus.org/" target="_blank">
                <i class="mdi mdi-help-circle"></i>
                <span>{{ $t('Help Center') }}</span>
              </NuxtLink>
            </li>
            <li class="app-navbar__drawer__menu__item">
              <NuxtLink
                to="https://www.transkribus.org/contact/support"
                target="_blank"
              >
                <i class="mdi mdi-face-agent"></i>
                <span>{{ $t('Request Support') }}</span>
              </NuxtLink>
            </li>
            <li class="app-navbar__drawer__menu__item">
              <NuxtLink
                :to="
                  localePath({
                    name: 'changelog',
                  })
                "
              >
                <i class="mdi mdi-creation"></i>
                <span>{{ $t('Whats new') }}</span>
              </NuxtLink>
            </li>
            <hr class="w-full border-gray-50 my-1" />
            <li class="app-navbar__drawer__menu__item">
              <a @click="showPrivacySettings">
                <i class="mdi mdi-cookie"></i>
                <span>{{ $t('Privacy Settings') }}</span>
              </a>
            </li>
            <li class="app-navbar__drawer__menu__item">
              <NuxtLink to="https://legal.transkribus.org/" target="_blank">
                <i class="mdi mdi-scale-balance"></i>
                <span>{{ $t('Legal Information') }}</span>
              </NuxtLink>
            </li>
            <hr class="w-full border-gray-50 my-1" />
            <li class="app-navbar__drawer__menu__item">
              <div>
                <i class="mdi mdi-web"></i>
                <span>
                  <select
                    @input="setAndStoreLocale($event.target.value)"
                    placeholder="Select language"
                    style="box-shadow: none; width: auto; padding: 0"
                  >
                    <option
                      v-bind:selected="
                        configStore?.config?.locale === locale.code
                      "
                      v-for="(locale, index) in availableLocales"
                      :key="`Lang${index}`"
                      :value="locale.code"
                    >
                      {{ locale.code.toUpperCase() }}
                    </option>
                  </select>
                </span>
              </div>
            </li>
            <hr class="w-full border-gray-50 my-1" />
            <li class="app-navbar__drawer__menu__item">
              <a @click="logout">
                <i class="mdi mdi-logout"></i>
                <span>{{ $t('Logout') }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import JobSlider from '~/components/Job/Slider.vue'
import PremiumModal from '~/components/Modals/Premium.vue'

const { locale, setLocale, t } = useI18n()
const i18n = useI18n()
const availableLocales = i18n.locales
const { $sentry } = useNuxtApp()
const { userProfile, keycloak } = useKeycloak()
const { $bus, $posthog, $features } = useNuxtApp()
const route = useRoute()
const localePath = useLocalePath()
const config = useRuntimeConfig()
const loadingIndex = ref<number | null>(null)
const configStore = useConfig()
const settings = ref<any>(null)
const drawerOpen = ref<boolean>(false)

onMounted(async () => {
  if (!configStore.config) {
    await configStore.fetchConfig()
  }
  settings.value = configStore.config
  $sentry.setUser({
    email: userProfile.Email,
  })
  const feedback = $sentry.feedbackIntegration({
    autoInject: false,
    colorScheme: 'light',
    showBranding: false,
    showName: false,
    showEmail: false,
    enableScreenshot: true,
    themeLight: {
      submitBackground: '#193060',
      submitBackgroundHover: '#101e3d',
      inputOutlineFocus: '2px solid #193060',
      boxShadow: '0 0 1px rgba(0, 0, 0, 0.1)',
    },
  })

  feedback.attachTo(document.querySelector('#sentry-bug'), {
    formTitle: t('We’re here to improve!!'),
    submitButtonLabel: t('Send Report'),
    messageLabel: t('Describe the issue'),
    messagePlaceholder: t(
      'Tell us what went wrong and what you expected to happen.'
    ),
    cancelButtonLabel: t('Cancel'),
    successMessageText: t(
      'Please note: This report helps us fix bugs. We won’t reply directly to this submission.'
    ),
  })
})

watch(
  () => route.fullPath,
  () => {
    setTimeout(() => {
      loadingIndex.value = null
    }, 100)
  }
)

const navbarItems = computed(() => {
  const baseItems = [
    { name: 'Desk', path: '/', icon: 'space_dashboard' },
    {
      name: 'Models',
      path: $features.dataLabTrain ? '/models' : '/training',
      icon: 'model_training',
    },
    { name: 'Sites', path: '/sites', icon: 'web_asset' },
  ]
  return baseItems
})

const currentPage = computed(() => {
  if (route.path.includes('training')) return '/training'
  if (route.path.includes('models')) return '/models'
  if (route.path.includes('data-lab')) return '/data-lab'
  if (route.path.includes('sites')) return '/sites'
  if (route.path.includes('learn')) return '/learn'
  if (route.path.includes('quality-control')) return '/quality-control'
  else return '/'
})

const isAlpha = computed(() => {
  return config.public.ENVIRONMENT_NAME === 'alpha'
})
// $posthog.has_opted_out_capturing(),
const posthogEnabled = ref(!$posthog.has_opted_out_capturing()) // Initialize with current state

watch(posthogEnabled, newValue => {
  if (newValue) {
    $posthog.opt_in_capturing()
  } else {
    $posthog.opt_out_capturing()
  }
})

function togglePosthog() {
  posthogEnabled.value = !posthogEnabled.value // Toggle the state
}

function handleClick(index: number, navbarItem: any, event): void {
  // do not set loading index if the item is already active
  // if the item is active, the page will not reload,
  // thus we should not show the loading animation
  console.log('click:', navbarItem, isActive(navbarItem.path))
  if (
    navbarItem &&
    !isActive(navbarItem.path) &&
    !(event.ctrlKey || event.metaKey)
  ) {
    loadingIndex.value = index
  }
}

function setAndStoreLocale(locale: string) {
  settings.value.locale = locale
  setLocale(locale)
  configStore.updateConfig(settings.value)
}

function isActive(path: string): boolean {
  return path === currentPage.value
}

function emitSlideOver() {
  const slideOver = {
    slideOver: 'Jobs',
    spec: route.params.collectionid,
    showSpec: false,
    slideContent: {
      component: JobSlider,
      data: {},
    },
  }
  $bus.emit('slideOver', slideOver)
}

function openPremium() {
  const modal = {
    modalContent: {
      component: PremiumModal,
      props: {
        heading: t(
          'Upgrade and become a <span style="font-family: \'Lobster Two\'">Transkribus</span> Scholar'
        ),
        subheading: t('Enjoy Transkribus Scholar'),
        description: t(
          'Unlock the full potential of Transkribus - where cutting-edge text recognition meets advanced field and table models. Elevate your research today with exclusive features designed for excellence. Try Scholar now and transform your workflow!'
        ),
        features: [
          t('Unleash super models'),
          t('Field & table recognition'),
          t('Smart Search'),
          t('Transkribus Sites'),
          t('Advanced processing speed'),
        ],
        image: 'premium-archivist',
      },
      events: {
        upgrade: () => {
          $posthog.capture('upgrade:upgrade_now_click', {
            location: 'user_menu',
          })
        },
        trialStart: () => {
          $posthog.capture('upgrade:trial_start', {
            location: 'user_menu',
          })
        },
      },
    },
  }
  let props = {
    noPadding: true,
    maxFit: true,
  }

  $bus.emit('open-modal', { modal: modal, props: props })
}

const showPrivacySettings = () => {
  if ((window as any).UC_UI) {
    ;(window as any).UC_UI.showSecondLayer()
  }
}

function logout() {
  keycloak?.logout()
  $posthog.reset()
}
</script>

<style>
#sentry-feedback {
  --border-radius: 0.5rem;
}
</style>
