<template>
  <TailwindComponentsCommandPalette
    :items="filteredtagsDoc"
    :listIcon="listIcon"
    :listType="'document'"
    :isLoading="isLoading"
    :placeholder="'Search documents by title/ID'"
    :label="label"
    :helpText="helpText"
    @search="getFilteredTags"
  ></TailwindComponentsCommandPalette>
</template>

<script>
export default {
  setup() {
    const collectionsApi = useCollections()
    return {
      collectionsApi,
    }
  },
  data() {
    return {
      tagsDoc: [],
      documentData: [],
      filteredtagsDoc: [],
      selectedDoc: null,
      awaitingSearch: false,
      timeout: null,
      listIcon: 'file-document',
      isLoading: false,
    }
  },
  props: {
    helpText: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  watch: {
    selectedDoc: {
      handler(value) {
        if (value) {
          this.$router.push(
            this.localePath(
              '/collection/' +
                value.collectionList.colList[0].colId +
                '/doc/' +
                value.docId
            )
          )
        }
      },
    },
  },
  async mounted() {
    this.isLoading = true
    let data

    try {
      data = await this.collectionsApi.findDocuments({
        offset: 0,
        limit: 100,
      })
      this.documentData = data.reverse()
      this.filteredtagsDoc = this.documentData
      this.isLoading = false
    } catch (err) {
      this.isLoading = false
      return
    }
  },
  methods: {
    getFilteredTags(text) {
      this.isLoading = true
      clearTimeout(this.timeout)
      var self = this
      this.timeout = setTimeout(async () => {
        let data
        let isnum = /^\d+$/.test(text)
        let id
        let title
        if (isnum) {
          id = text
        } else {
          title = text
        }

        try {
          data = await this.collectionsApi.findDocuments({
            offset: 0,
            limit: 100,
            title,
            id,
          })
          this.documentData = data

          this.filteredtagsDoc = this.documentData

          this.isLoading = false
        } catch (err) {
          this.isLoading = false

          return
        }
      }, 500)
    },
    emitRemoved() {
      this.$emit('removedTag', 'wasRemoved')
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
  },
}
</script>

<style scoped>
.box {
  padding: 0.25rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
