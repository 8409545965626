const MAPPINGS = {
  'search-form': {
    term: 'term',
    titles: 'f_title',
    fuzziness: 'f',
    page: 'p',
    view: 'view',
    colId: 'colId',
  },
  paginated: {
    page: 'p',
  },
  hierarchy: {
    hierarchy: 'hierarchy',
    level: 'l',
    page: 'p',
  },
  browser: {
    display: 'd',
    textVisible: 'v',
    term: 'term',
    fuzziness: 'f',
  },
  'tagsearch-form': {
    term: 'term',
    type: 'type',
    page: 'p',
    id: 'id',
  },
}

// This function creates a mapping object that maps keys to their corresponding values.
function createMapperFromMapping(mapping) {
  // This line uses the reduce method to create and return a new object with the keys and values swapped.
  const reverseMapping = Object.keys(mapping).reduce(
    (obj, key) => Object.assign(obj, { [mapping[key]]: key }),
    {}
  )

  // This returns an object containing two methods: `toURL` and `fromURL`.
  return {
    // This method takes two parameters: `source` and `ignore`. It converts the `source` object into a new object where the keys are mapped using the `mapping` object. It ignores any keys in `ignore`.
    toURL(source, { ignore = [] } = {}) {
      if (typeof source === 'string') {
        return mapping[source]
      }

      const target = {}
      for (const key in source) {
        if (!ignore.includes(key)) {
          target[mapping[key]] = source[key]
        }
      }
      return target
    },

    // This method takes a single parameter `source` and converts it back to its original form using the `reverseMapping` object.
    fromURL(source) {
      if (typeof source === 'string') {
        return reverseMapping[source]
      }

      const target = {}
      for (const key in source) {
        target[reverseMapping[key]] = source[key]
      }
      return target
    },
  }
}

export function createWatchers(
  target,
  handler,
  { ignore = [], immediate = true, deep = false } = {}
) {
  const watchers = {}
  const mapping = MAPPINGS['search-form']
  const keys = Object.keys(mapping).filter(key => !ignore.includes(key))
  for (const key of keys) {
    const value = mapping[key]
    console.assert(value !== undefined, 'unexpected value undefined')
    watchers[target + '.' + value] = {
      handler:
        typeof handler === 'function'
          ? function(...args) {
              handler.call(this, ...args)
            }
          : handler,
      immediate,
      deep,
    }
  }
  return watchers
}

export function createMapper(mappingName) {
  if (!(mappingName in MAPPINGS)) {
    throw new Error(`'${mappingName}' not in mappings`)
  }
  const mapping = MAPPINGS[mappingName]
  return createMapperFromMapping(mapping)
}
