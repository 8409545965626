<template>
  <div class="drawer drawer-end">
    <input
      :id="title"
      type="checkbox"
      class="drawer-toggle"
      ref="drawer"
      @change="inputChange"
      :tabindex="isOpen ? '0' : '-1'"
    />
    <!-- added translate 0 to prevent drawer content from shifting -->
    <div style="--tw-translate-x: 0" class="drawer-content z-0">
      <slot name="page-content"></slot>
    </div>
    
    <div class="drawer-side" :class="{ 'z-[10000]': overlayModal, 'transition-applied': $features.newUI }">
      <label :for="title" class="drawer-overlay"></label>
      <div
        class="app-slide-over"
        :class="{ 'overflow-hidden': !isOpen }"
        :style="[{ 'max-width': maxWidth }, { 'min-width': minWidth }]"
      >
        <BaseButton
          :icon="'close'"
          class="app-slide-over__close"
          :class="`!text-${buttonType}`"
          :type="`text--${buttonType}`"
          @click="handleClose"
        ></BaseButton>

        <slot name="slide-content"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    overlayModal?: boolean
    title?: string
    buttonType?: string
    maxWidth?: string
    minWidth?: string
  }>(),
  {
    overlayModal: false,
  }
)

let isOpen = ref(false)
const drawer = ref({ checked: false })

const { $bus } = useNuxtApp()
const route = useRoute()

onMounted(() => {
  $bus.on('slideOverClose', handleClose)
  window.addEventListener('keydown', handleKeyDown)
  isOpen.value = drawer.value.checked
})

onUnmounted(() => {
  $bus.off('slideOverClose', handleClose)
})

const inputChange = (event: Event) => {
  return (isOpen.value = (event.target as HTMLInputElement)?.checked)
}

const handleKeyDown = (event: Event) => {
  if (event.key === 'Escape') {
    if (drawer.value) {
      drawer.value.checked = false
    }
    isOpen.value = false
  }
}

const handleClose = () => {
  drawer.value.checked = false
  isOpen.value = false
}
</script>
<style scoped>
.drawer-side.transition-applied > :not(.drawer-overlay) {
  @apply ease-in-out;
  transition-duration: 0.5s;
}
</style>