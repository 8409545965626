import { defineStore } from 'pinia'
import deepEqual from 'fast-deep-equal'

const sitesApi = useSites()

interface Locale {
  code: string
  [key: string]: any
}

export interface Site {
  locales: Locale[]
  [key: string]: any
}

export const useSiteStore = defineStore('sitesStore', {
  state: () => ({
    language: 'en',
    rsCollection: null,
    url: null,
    collid: null,
    unsavedChanges: false,
    collIdNameMapping: {} as Record<number, string>,
    filters: {} as Record<string, any>,
    term: '',
    fuzziness: 1,
    currentPage: 1, // TODO, currently never set
    sortField: 'id', //TODO, currently never set and never used
  }),
  actions: {
    update(params: any) {
      for (const key in params)
        if (!deepEqual(this[key], params[key])) this[key] = params[key]
    },
    setFilter(key: string, value: any) {
      if (!key || !value) return

      this.filters[key] = [value]
    },
    removeFilter(key : string) {
      delete this.filters[key]
    },
    resetFilters() {
      this.filters = {}
    },
    appendFilter(key: string, value: any) {
      if (!key || !value) return

      if (!this.filters[key]) {
        this.filters[key] = [value]
      } else {
        this.filters[key].push(value)
      }
    },
    async fetchRsCollection(url: string) {
      try {
        let data: Site
        if (url !== this.rsCollection?.url || !this.rsCollection) {
          data = await sitesApi.fetchSite({ url })

          // TODO - add missing translation keys for all languages
          data = await setMissingTranslationKeys(data)

          const selectedLanguage =
            setSiteLanguage(data.locales) || data.locales[0].code

          setCookie('site-language', selectedLanguage)

          this.update({ language: selectedLanguage })
        } else {
          data = this.rsCollection
        }

        this.rsCollection = data
        const permissions = usePermissions()
        permissions.setSitePermissions(data)
      } catch (e) {
        console.error(e)
      }
    },
    async updateSites(url: string) {
      let data = await sitesApi.fetchSite({ url })

      if (!this.language) {
        this.update({ language: data.locales[0].code })
      }

      this.rsCollection = data
      const permissions = usePermissions()
      permissions.setSitePermissions(data)
    },
    setSites(sites: Site[]) {
      this.rsCollection = sites
      const permissions = usePermissions()
      permissions.setSitePermissions(sites)
    },
    setLanguage(language: string) {
      setCookie('site-language', language)
      this.update({ language: language })
    },
    setUnsavedChanges(unsavedChanges: boolean) {
      this.update({ unsavedChanges: unsavedChanges })
    },
    async fetchCollIdNameMapping(url) {
      const { $features } = useNuxtApp()
      try {
        if (url) {
          let data: Collection[] = await sitesApi.fetchColNames({ url: url })
          data.forEach(coll => {
            this.collIdNameMapping[coll.colId] = coll.colName
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
})

function setSiteLanguage(siteLocals: Locale[]): string | undefined {
  const siteLanguageCookie = getCookies('site-language')

  if (siteLanguageCookie) {
    const isMatch = siteLocals.some(
      siteLocal => siteLocal.code === siteLanguageCookie
    )

    if (isMatch) {
      return siteLanguageCookie
    }
  } else {
    return setPreferredLanguage(siteLocals)
  }
}

function getCookies(name: string): string | null {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()

    if (cookie.startsWith(name + '=')) {
      return decodeURIComponent(cookie.substring(name.length + 1))
    }
  }

  return null
}

async function setMissingTranslationKeys(data: Site) {
  try {
    if (data?.locales?.length > 0) {
      data.locales = await Promise.all(
        data.locales.map(async locale => {
          let masterLocale
          try {
            // Try to read the master JSON for the current locale
            masterLocale = await import(`~/locales/sites/${locale.code}.json`)
          } catch (error) {
            // If the master JSON for the current locale doesn't exist, use en.json
            masterLocale = await import(`~/locales/sites/en.json`)
          }

          if (masterLocale?.default && locale.translations) {
            const masterKeys = Object.keys(masterLocale.default)
            const localeKeys = Object.keys(locale.translations)
            const differingTranslations = {}

            masterKeys.forEach(key => {
              if (!localeKeys.includes(key)) {
                locale.translations[key] = masterLocale.default[key]
                differingTranslations[key] = locale.translations[key]
              }
            })
          }
          return locale
        })
      )

      return data
    }
  } catch (error) {}
}

function setCookie(name: string, value: string): void {
  document.cookie = name + '=' + encodeURIComponent(value) + '; path=/'
}

function setPreferredLanguage(siteLanguages: Locale[]): string | undefined {
  let matchedLanguage = null
  let isEnMatched = false
  const browserLanguages = navigator.languages || [navigator.language]

  if (browserLanguages.length) {
    for (let i = 0; i < browserLanguages.length; i++) {
      const browserLang = browserLanguages[i].slice(0, 2)

      const isMatched = siteLanguages.some(locale => {
        const localeLang = locale.code.slice(0, 2)

        if (browserLang === localeLang && !isEnMatched) {
          matchedLanguage = locale.code
          isEnMatched = true

          return true
        }
      })
      if (isMatched) {
        return matchedLanguage
      }
    }
  }
}
