<template>
  <div class="px-4 sm:px-6 lg:px-8 text-text-primary">
    <div v-if="header" class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold text-gray-900">
          {{ header.title }}
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          {{ header.description }}
        </p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {{ header.button }}
        </button>
      </div>
    </div>
    <div class="flow-root">
      <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full align-middle">
          <TailwindComponentsLoading
            v-if="!data"
            fullScreen
          ></TailwindComponentsLoading>
          <table v-else class="min-w-full divide-y divide-gray-100">
            <div v-if="data && data.length === 0" class="text-center p-4">
              {{ $t('No Data Available') }}
            </div>
            <thead>
              <tr>
                <template v-for="column in columns">
                  <th
                    v-if="column.display"
                    :key="column.field"
                    :class="{ '!p-0': isSmall }"
                    scope="col"
                    class="px-3 py-4 text-left text-xs font-semibold text-text-secondary"
                  >
                    <span v-if="column.customHeader" slot="customHeader-field">
                      <slot :name="column.slotNameHeader"></slot>
                    </span>
                    <span v-else> {{ column.label?.toUpperCase() }}</span>
                  </th>
                </template>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <tr
                class=""
                v-for="(row, index) in data"
                :key="index"
                @click="handleRowClick(row)"
                :class="{
                  'cursor-not-allowed':
                    restrictSelection &&
                    row[restrictSelection?.field] === restrictSelection?.value,
                  'cursor-pointer':
                    selectable &&
                    !(
                      restrictSelection &&
                      row[restrictSelection?.field] === restrictSelection?.value
                    ),
                  'app-table__row--selected':
                    row === highlightRow || (!highlightRow && row === data[0]),
                }"
              >
                <template v-for="column in columns">
                  <td
                    v-if="column.display"
                    :key="column.field"
                    :class="[{ '!p-0': isSmall || column.isButton }]"
                    class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900"
                  >
                    <span
                      v-if="column.custom"
                      slot="custom-field"
                      class="flex flex-wrap gap-y-1"
                    >
                      <slot
                        :name="column.slotName"
                        :row="row"
                        :column="column"
                        :index="index"
                      ></slot>
                    </span>
                    <div v-else-if="column.header1">
                      <div class="flex text-primary font-bold">
                        {{ row[column.header1] }}
                        {{ row[column.header2] }}
                      </div>
                      <div>
                        {{ row[column.field] }}
                      </div>
                    </div>
                    <span v-else> {{ row[column.field] }}</span>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    data: []
    header?: { title?: string; description?: string; button?: string }
    columns?: Array<any>
    isSmall?: boolean
    restrictSelection?: { field: string; value: string }
    selectable?: boolean
  }>(),
  {}
)

const highlightRow = ref(null)

const emit = defineEmits()

function handleRowClick(row) {
  highlightRow.value = row
  if (
    props.restrictSelection &&
    row[props.restrictSelection?.field] === props.restrictSelection?.value
  ) {
    return
  } else {
    emit('clickRow', row)
  }
}
</script>
