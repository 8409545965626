<template>
  <div
    class="flex items-center w-full lg:overflow-visible overflow-hidden justify-end absolute lg:relative inset-0"
    :class="{
      'overflow-visible bg-primary z-40': searchIsOpen,
    }"
  >
    <!-- search icon -->
    <i
      v-if="!searchIsOpen"
      class="mdi mdi-magnify inline-flex lg:hidden text-2xl cursor-pointer app-animation absolute right-6"
      @click="searchIsOpen = !searchIsOpen"
    ></i>
    <!-- close icon -->
    <i
      v-else
      class="mdi mdi-close inline-flex lg:hidden text-2xl cursor-pointer mx-2 app-animation"
      :class="{
        'rotate-90': searchIsOpen,
      }"
      @click="searchIsOpen = !searchIsOpen"
    ></i>
    <!-- search wrapper -->
    <div
      class="app-navbar__search__wrapper translate-x-full lg:translate-x-0 w-full max-w-[550px] opacity-100 lg:opacity-100 app-animation z-10"
      :class="{
        '!max-w-full !opacity-100 !translate-x-0 pr-4': searchIsOpen,
      }"
    >
      <!-- Full text -->

      <BaseInput
        :placeholder="$t('Search by collection title or ID')"
        :leading="'magnify'"
        :name="'search'"
        :type="'search'"
        :id="'navbar-search-input'"
        class="app-navbar__search w-full flex flex-1"
        @input="openSearchDialog" 
        @click="openSearchDialog"
      />
      <!-- if there is already input use it in the dialog-->
    </div>
    <NavbarSearchDialog :open="isSearchDialogOpen" @close="closeDialog" />
  </div>
</template>

<script setup>
const isSearchDialogOpen = ref(false)
const searchIsOpen = ref(false)

const openSearchDialog = () => {
  if (!isSearchDialogOpen.value) {
    isSearchDialogOpen.value = true
  }
}
const closeDialog = () => {
  isSearchDialogOpen.value = false
}


const focusSearchBar = () => {
  openSearchDialog()
}

const handleKeydown = (event) => {
  if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
    console.log('ole ole')
    event.preventDefault()
    focusSearchBar()
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeydown)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown)
})

</script>
