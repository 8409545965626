<template>
  <div
    class="app-input"
    :class="{
      'app-input--icon-left': leading,
      'app-input--icon-right': trailing,
      'app-input--error': error,
      'app-input--warning': warning,
    }"
  >
    <BaseTableBlock v-if="label || noteText">
      <template v-slot:row-1>
        <label
          v-if="label"
          :for="id"
          class="inline-block text-sm font-medium leading-6 text-primaryG-700 mb-0"
          >{{ $t(label) }}</label
        >
        <p
          class="text-sm text-secondaryG-700"
          v-html="noteText"
          v-if="noteText"
        />
      </template>
    </BaseTableBlock>

    <div class="app-input__wrapper">
      <div class="flex-1 relative">
        <input
          :disabled="disabled"
          :type="type"
          :name="name"
          :id="id"
          :placeholder="placeholder"
          :value="value"
          :min="min"
          :max="max"
          :maxLength="maxLength"
          autocomplete="off"
          class="app-input__input"
          :class="{
            'app-input__input--noBorder': noBorder,
            'app-input__input--medium': medium,
            'app-input__input--search': search,
            'app-input__input--search--small': searchSmall,
            'app-input__input--search--smaller': searchSmaller,
            'app-input__input--advancedSearch': advancedSearch,
          }"
          :style="`width: ${width}; height: ${height}`"
          @focus="$emit('focus', $event.target.value)"
          @keyup.enter="$emit('enter', $event.target.value)"
        />
        <slot name="append"></slot>
        <BaseButton
          v-if="showReset && value"
          small
          @click="$emit('reset')"
          :icon="'close'"
          style="opacity: 1"
          class="app-input__close"
        ></BaseButton>
        <BaseButton
          v-if="inLineButton"
          small
          icon
          @click="$emit(inLineButton)"
          :icon="inLineButton"
          style="opacity: 1"
          class="app-input__close"
        ></BaseButton>
        <i
          v-if="error"
          class="mdi mdi-alert-circle app-input__icon app-input__icon--error"
        ></i>
        <i
          v-if="warning"
          class="mdi mdi-alert-circle app-input__icon app-input__icon--warning"
        ></i>
        <i
          v-if="leading"
          :class="[
            `mdi mdi-${leading} app-input__icon app-input__icon--leading`,
          ]"
        ></i>
        <i
          v-if="trailing"
          :class="[
            `mdi mdi-${trailing} app-input__icon app-input__icon--trailing`,
          ]"
        ></i>
      </div>
    </div>

    <p
      v-html="helpText"
      class="mt-2 ml-2 text-sm text-gray-500"
      v-if="helpText"
    />
    <p v-html="errorText" class="m-0 text-sm text-danger" v-if="error" />
    <p v-html="warningText" class="m-0 text-sm text-warning" v-if="warning" />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    inLineButton: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    helpText: {
      type: String,
      default: null,
    },
    warningText: {
      type: String,
      default: null,
    },
    errorText: {
      type: String,
      default: null,
    },
    noteText: {
      type: String,
      default: null,
    },
    leading: {
      type: String,
      default: null,
    },
    trailing: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    showReset: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    withDropdown: {
      type: Boolean,
      default: false,
    },
    // New prop for selectedItems
    selectedItems: {
      type: Array,
      default: () => [],
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    // for strings
    maxLength: {
      type: Number,
      default: null,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchSmall: {
      type: Boolean,
      default: false,
    },
    searchSmaller: {
      type: Boolean,
      default: false,
    },
    advancedSearch: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // Method for deleting item when clicking on "x"
    deleteSelectedItem(item) {
      this.$emit('delete-item', item)
    },
  },
}
</script>
