import Keycloak from 'keycloak-js'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const config = useRuntimeConfig()
    const { $posthog, $features } = useNuxtApp()

    if (config.public.name === 'test') {
      console.log('KEYCLOAK is not available for test runs.')
      return
    }

    const store = useKeycloak()

    if (store.loggedIn) {
      return
    }

    const initOptions = {
      url: config.public.KEYCLOAK_URL,
      realm: config.public.KEYCLOAK_REALM,
      clientId: config.public.KEYCLOAK_CLIENT_ID,
      onLoad: 'login-required',
    }

    const keycloak = new Keycloak(initOptions)

    const refreshToken = async () => {
      try {
        console.log('Attempting to refresh token...')
        const refreshed = await keycloak.updateToken(30) // Refresh token if it will expire in 30 seconds
        if (refreshed) {
          console.log('Token refreshed successfully')
          store.setup(keycloak)
          store.setLogined(true)
        } else {
          console.log('Token is still valid')
        }
      } catch (error) {
        console.log(
          'Failed to refresh the token, or the session has expired',
          error
        )
        keycloak.logout() // Redirect to login page if token refresh fails
      }
    }

    keycloak.onTokenExpired = () => {
      console.log('Token expired, attempting to refresh...')
      refreshToken()
    }
    // keycloak version 26.05
    try {
      const auth = await keycloak.init({
        onLoad: initOptions.onLoad,
        checkLoginIframe: false,
      })
      if (!auth) {
        console.log('User is not authenticated')
        await keycloak.login()
      } else {
        console.log('User is authenticated, refreshing token...')
        refreshToken()
        store.setup(keycloak)
        store.setLogined(true)
      }
      // Identify users in PostHog
      $posthog.identify(
        store.userProfile.UserId, // user's unique identifier
        { email: store.userProfile.Email, name: store.userProfile.Name } // additional person properties
      )
      //create an alias to track the user's activity even if there is no userId yet
      $posthog.alias(store.userProfile.Email, store.userProfile.UserId)
    } catch (error) {
      keycloak.logout() // Redirect to login page if initialization fails
    }
  }
})
