<template>
  <div
    v-if="
      layout === KINDS.DEFAULT || layout === KINDS.DEFAULT_WITHOUT_SUBHEADER
    "
    class="app-layout app-layout--sites"
  >
    <LazyTailwindComponentsSlideOver
      :title="slideOver?.name"
      btn-type="secondary"
      ref="drawer"
      :class="'w-full h-full'"
      :buttonType="rsCollection?.config?.theme"
    >
      <template v-slot:slide-content>
        <component
          :is="slideOver?.content.component"
          v-bind="slideOver?.content.props"
          v-on="slideOver?.content.events || {}"
      /></template>
      <template v-slot:page-content>
        <div class="flex flex-col h-screen">
          <NotPublicBanner v-if="!isPublished && layout !== KINDS.EDIT" />
          <header class="app-header app-header--fixed">
            <slot name="header"> <TheNavbar></TheNavbar></slot>
          </header>

          <main class="app-main flex-grow overflow-auto">
            <slot></slot>
          </main>
          <footer class="app-footer">
            <TheFooter />
          </footer>
        </div>
      </template>
    </LazyTailwindComponentsSlideOver>
  </div>
  <div v-else-if="layout === KINDS.EDIT" class="app-layout app-layout--sites">
    <LazyTailwindComponentsSlideOver
      :title="slideOver?.name"
      btn-type="secondary"
      ref="drawer"
      :class="'w-full h-full'"
      :buttonType="rsCollection?.config?.theme"
    >
      <template v-slot:slide-content>
        <component
          :is="slideOver?.content.component"
          v-bind="slideOver?.content.props"
          v-on="slideOver?.content.events || {}"
      /></template>
      <template v-slot:page-content>
        <header
          class="fixed top-0 left-0 right-0 z-[1000]"
          :class="editView ? 'max-h-none' : 'max-h-0'"
        >
          <EditNavbar @change-view="editView = !editView"></EditNavbar>
          <slot name="subheader"></slot>
        </header>
        <div class="flex app-animaion" :class="editView ? 'mt-20' : 'mt-0'">
          <div
            class="p-0 bg-uilight h-auto overflow-auto app-animation"
            :class="
              editView
                ? 'w-1/3 opacity-100 max-h-none min-h-[calc(100vh_-_80px)]'
                : 'w-0 opacity-0 max-h-0'
            "
          >
            <EditSidebar />
          </div>
          <div
            class="p-0 relative h-auto overflow-auto app-animation"
            :class="editView ? 'w-2/3' : 'w-full'"
          >
            <div
              class="flex flex-col h-full"
              :class="{ 'min-h-screen': !editView }"
            >
              <header>
                <slot name="header"> <TheNavbar></TheNavbar></slot>
              </header>
              <main class="h-auto flex-1">
                <slot class=""></slot>
              </main>
              <footer>
                <slot name="footer"
                  ><TheFooter class="bg-color-[#0099aa] h-20"></TheFooter
                ></slot>
              </footer>
            </div>
          </div>
        </div>
      </template>
    </LazyTailwindComponentsSlideOver>
  </div>

  <!-- Modal -->
  <!-- Notification -->
  <transition-group
    tag="div"
    aria-live="assertive" 
    class="pointer-events-none fixed inset-0 flex flex-col items-end px-4 py-6 sm:items-start sm:p-6"
    :name="`notification`"
  >
    <div
      class="notification-item"
      v-for="(notification, index) in notifications"
      :key="notification.key"
    >
      <LazyBaseNotification
        :key="notification.key"
        :message="notification.message"
        :position="notification.position"
        :type="notification.type"
        :duration="notification.duration"
        :indefinite="notification.indefinite"
        :canCloseOnHover="notification.closeable"
        :open="notification.open"
        @close="closeNotification(index)"
      ></LazyBaseNotification>
    </div>
  </transition-group>

  <LazyBaseModal
    v-for="(modal, index) in modals"
    :key="index"
    :isFullscreen="modal.isFullscreen"
    :isMedium="modal.isMedium"
    :noPadding="modal.noPadding"
    :maxFit="modal.maxFit"
    :customheader="modal.customHeader"
    :modalInfo="modal.modalInfo"
    :open="modal.open"
    @close="closeModal"
  />
</template>

<script>
import TheNavbar from '~/components/Sites/Navbar.vue'
import TheFooter from '~/components/Sites/TheFooter.vue'
import SlideOver from '~/components/TailwindComponents/SlideOver.vue'
import NotPublicBanner from '~/components/Sites/NotPublicBanner.vue'

import EditSidebar from '~/components/Sites/EditSidebar.vue'
import EditNavbar from '~/components/Sites/NavbarEdit.vue'
import Keycloak from 'keycloak-js'

import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'

const KINDS = {
  DEFAULT: 'default',
  DEFAULT_WITHOUT_SUBHEADER: 'default-without-subheader',
  EDIT: 'edit',
}

export default {
  async setup() {
    const store = useKeycloak()
    const config = useRuntimeConfig()

    const initOptions = {
      url: config.public.KEYCLOAK_URL,
      realm: config.public.KEYCLOAK_REALM,
      clientId: config.public.KEYCLOAK_CLIENT_ID,
      onLoad: 'check-sso',
    }

    const keycloak = new Keycloak(initOptions)
    const refreshToken = () => {
      keycloak
        .updateToken(5)
        .then(refreshed => {
          const asDate = exp => new Date(exp * 1000)

          store.setup(keycloak)
          store.setLogined(true)
        })
        .catch(function () {
          console.log('Failed to refresh the token, or the session has expired')
        })
    }

    keycloak.onTokenExpired = function () {
      refreshToken()
    }
    await keycloak
      .init({ onLoad: initOptions.onLoad, checkLoginIframe: false })
      .then(auth => {
        if (!auth) {
        } else {
          refreshToken()
          store.setup(keycloak)
          store.setLogined(true)
        }
      })
    const userProfile = store?.userProfile

    return {
      userProfile,
    }
  },
  components: {
    TheNavbar,
    EditSidebar,
    EditNavbar,
    TheFooter,
    SlideOver,
    NotPublicBanner,
  },
  props: {
    kind: {
      type: String,
      default: KINDS.DEFAULT,
    },
  },
  watch: {
    rsCollection(value) {},
  },
  computed: {
    ...mapState(useSiteStore, ['rsCollection', 'language', 'unsavedChanges']),
    isPublished() {
      return this.rsCollection?.status === 'Published'
    },
    userId() {
      return this.userProfile?.UserId
    },
    layout() {
      const found = this.rsCollection?.additionalUsers?.find(
        user => user.userId === this.userId
      )
      if (
        found ||
        this.userId === this.rsCollection?.userId ||
        this.userProfile?.IsAdmin
      ) {
        return 'edit'
      } else {
        return 'default'
      }
    },
  },
  data() {
    return {
      modalFullscreen: false,
      modalMedium: false,
      noPadding: false,
      modals: [],
      notifications: [],
      toggleRef: 'modal',
      customheader: '',
      slideOver: {
        name: '',
        spec: '',
        content: '',
        props: '',
        maxWidth: '',
      },
      modal: {
        content: '',
      },
      editView: true,
    }
  },
  created() {
    this.$bus.on('slideOver', this.setSlideOver)
    this.$bus.on('notification', this.createNotification)
    this.$bus.on('modal', this.setModal)
    this.$bus.on('open-modal', this.createModal)
    this.$bus.on('close-modal', this.closeModal)
    this.KINDS = KINDS
  },
  destroyed() {
    this.$bus.off('slideOver', this.setSlideOver)
    this.$bus.off('notification', this.createNotification)
    this.$bus.off('modal', this.setModal)
    this.$bus.off('open-modal', this.createModal)
    this.$bus.off('close-modal', this.closeModal)
    const styleTemp = document.querySelector('#sites-styles')

    if (styleTemp) {
      styleTemp.remove()
    }
  },
  beforeDestroy() {
    const styleTemp = document.querySelector('#sites-styles')

    if (styleTemp) {
      styleTemp.remove()
    }
  },
  mounted() {
    
  },
  methods: {
    sendNotification() {
      this.$bus.emit('notification', {
        message: this.$t('User search failed'),
        type: 'error',
      })
    },
    closeNotification(index) {
      console.log(this.notifications)
      // this.notifications.value = this.notifications.value.filter(
      //   (n, i) => i !== index
      // )
    },

    setSlideOver(data) {
      this.slideOver = {
        name: data.slideOver,
        spec: data.spec,
        content: markRaw(data.slideContent),
        props: data.slideContent.props,
      }
    },
    createNotification(obj) {
      const newNotification = {
        key: this.generateRandomKey(),
        message: obj?.message || '',
        position: obj?.position || 'right',
        type: obj?.type || 'info',
        duration: obj?.duration || 5000,
        indefinite: obj?.indefinite || false,
        open: false,
      }
      this.notifications = [newNotification, ...this.notifications]
    },
    generateRandomKey() {
      const characters = 'ABCDEF'
      let key = ''
      for (let i = 0; i < 10; i++) {
        key += characters.charAt(Math.floor(Math.random() * characters.length))
      }
      return key
    },
    createModal(data) {
      const newModal = {
        isFullscreen: data.props?.isFullscreen,
        isMedium: data.props?.isMedium,
        noPadding: data.props?.noPadding,
        maxFit: data.props?.maxFit,
        customHeader: data.props?.customHeader,
        modalInfo: markRaw(data.modal),
        open: false,
      }
      this.modals.push(newModal)
      newModal.open = true
    },

    closeModal() {
      const oldModal = this.modals.at(-1)
      oldModal.open = false
      setTimeout(() => {
        const index = this.modals.indexOf(oldModal)
        if (index !== -1) {
          this.modals.splice(index, 1)
        }
      }, 200)
    },
    setModal(data) {
      this.modalFullscreen = data.props?.isFullscreen
      this.toggleRef = data.props?.toggleRef
      this.customheader = data.props?.customHeader
      this.$refs.modal.openModal(data.modal)
      // this.$refs.modal.openModal(data)
    },
  },
}
</script>
<style>
.notification-item {
  @apply flex w-full flex-col items-center space-y-4 pb-2 sm:items-end;
}

.notification-leave-active {
  @apply absolute;
}

.notification-move {
  transition: transform 1s;
}
</style>
