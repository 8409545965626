import { default as buildEseffiSZ8DMeta } from "/opt/apps/transkribus_app/pages/build.vue?macro=true";
import { default as changelogVhqMrXf4T1Meta } from "/opt/apps/transkribus_app/pages/changelog.vue?macro=true";
import { default as indexwZoJAlLJIrMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/detail/[pageid]/index.vue?macro=true";
import { default as index0tij4HpJdbMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/edit/[pageid]/index.vue?macro=true";
import { default as indexRZhM61kkHkMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/edit/index.vue?macro=true";
import { default as indexRbmkkavlyhMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/index.vue?macro=true";
import { default as indexHES2Xt0ECbMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/index.vue?macro=true";
import { default as indexlHQl0jdKTjMeta } from "/opt/apps/transkribus_app/pages/collection/index.vue?macro=true";
import { default as indexN46pAdXDa6Meta } from "/opt/apps/transkribus_app/pages/data-lab/index.vue?macro=true";
import { default as indexQjhyC2ZwMtMeta } from "/opt/apps/transkribus_app/pages/data-lab/train/index.vue?macro=true";
import { default as guideMXaVefcVTDMeta } from "/opt/apps/transkribus_app/pages/guide.vue?macro=true";
import { default as indexvD7d4YvqU9Meta } from "/opt/apps/transkribus_app/pages/index.vue?macro=true";
import { default as indexUMKZ3DCoEnMeta } from "/opt/apps/transkribus_app/pages/invitations/[inviteid]/index.vue?macro=true";
import { default as indexlkRNpR0fn1Meta } from "/opt/apps/transkribus_app/pages/invitations/index.vue?macro=true";
import { default as jobsMcgJGwhlKlMeta } from "/opt/apps/transkribus_app/pages/jobs.vue?macro=true";
import { default as index3pafii4cj3Meta } from "/opt/apps/transkribus_app/pages/learn/index.vue?macro=true";
import { default as loginWc5rK6qQ1vMeta } from "/opt/apps/transkribus_app/pages/login.vue?macro=true";
import { default as indexwK752oTjBtMeta } from "/opt/apps/transkribus_app/pages/models/[type]/[modelId]/index.vue?macro=true";
import { default as indextq7J8ERWonMeta } from "/opt/apps/transkribus_app/pages/models/[type]/[modelId]/modify/index.vue?macro=true";
import { default as indexV63Sgms0yUMeta } from "/opt/apps/transkribus_app/pages/models/index.vue?macro=true";
import { default as indexHhBbHSHOo2Meta } from "/opt/apps/transkribus_app/pages/quality-control/index.vue?macro=true";
import { default as indexKBeml5z1ATMeta } from "/opt/apps/transkribus_app/pages/search/index.vue?macro=true";
import { default as indexpVdRqhJaYFMeta } from "/opt/apps/transkribus_app/pages/share/[hash]/index.vue?macro=true";
import { default as indexRc05IlraZTMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/about/index.vue?macro=true";
import { default as indexp371v0aWPEMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/browse/index.vue?macro=true";
import { default as index3d4k6cTdLXMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/content/[contentName]/index.vue?macro=true";
import { default as indexUE9bxe1FVtMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/detail/[pageid]/index.vue?macro=true";
import { default as indexa8JBJIFiX2Meta } from "/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/detail/index.vue?macro=true";
import { default as indexKdPkQEzfIqMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/index.vue?macro=true";
import { default as index12cQp5VGDhMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/imprint/index.vue?macro=true";
import { default as indexxADbEotZbTMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/index.vue?macro=true";
import { default as indexZtTG5lT1nlMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/search/index.vue?macro=true";
import { default as indexfUZo03EhcEMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/tags/index.vue?macro=true";
import { default as indexgKTCqU83lTMeta } from "/opt/apps/transkribus_app/pages/sites/index.vue?macro=true";
import { default as indexmVAuGbbPZ0Meta } from "/opt/apps/transkribus_app/pages/tags/[collectionid]/index.vue?macro=true";
import { default as index4YolltvPjlMeta } from "/opt/apps/transkribus_app/pages/tags/index.vue?macro=true";
import { default as indexuAQkrgui0uMeta } from "/opt/apps/transkribus_app/pages/training/[type]/[modelId]/index.vue?macro=true";
import { default as index26j7tE0Iv2Meta } from "/opt/apps/transkribus_app/pages/training/[type]/[modelId]/train-data/index.vue?macro=true";
import { default as indexulyq4QWZJ4Meta } from "/opt/apps/transkribus_app/pages/training/[type]/[modelId]/train-data/viewer/index.vue?macro=true";
import { default as indexXdihFnaiwnMeta } from "/opt/apps/transkribus_app/pages/training/gallery/index.vue?macro=true";
import { default as indexGJtUUWeFV7Meta } from "/opt/apps/transkribus_app/pages/training/index.vue?macro=true";
import { default as indeximmi45XxZdMeta } from "/opt/apps/transkribus_app/pages/upload-demo/index.vue?macro=true";
import { default as indexYxHtTetahJMeta } from "/opt/apps/transkribus_app/pages/upload/index.vue?macro=true";
import { default as index2Ad79aD9acMeta } from "/opt/apps/transkribus_app/pages/usage/index.vue?macro=true";
import { default as indexfWSuG00AO7Meta } from "/opt/apps/transkribus_app/pages/usage/manage-organisation/[organisationid]/index.vue?macro=true";
import { default as index8v9ec8gGcaMeta } from "/opt/apps/transkribus_app/pages/version/index.vue?macro=true";
import { default as indexhRZXvPcETFMeta } from "/opt/apps/transkribus_app/pages/welcome/index.vue?macro=true";
import { default as component_45stubKzQrTTlmreMeta } from "/opt/apps/transkribus_app/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubKzQrTTlmre } from "/opt/apps/transkribus_app/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: buildEseffiSZ8DMeta?.name ?? "build",
    path: buildEseffiSZ8DMeta?.path ?? "/build",
    meta: buildEseffiSZ8DMeta || {},
    alias: buildEseffiSZ8DMeta?.alias || [],
    redirect: buildEseffiSZ8DMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/build.vue").then(m => m.default || m)
  },
  {
    name: changelogVhqMrXf4T1Meta?.name ?? "changelog",
    path: changelogVhqMrXf4T1Meta?.path ?? "/changelog",
    meta: changelogVhqMrXf4T1Meta || {},
    alias: changelogVhqMrXf4T1Meta?.alias || [],
    redirect: changelogVhqMrXf4T1Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: indexwZoJAlLJIrMeta?.name ?? "collection-collectionid-doc-docid-detail-pageid",
    path: indexwZoJAlLJIrMeta?.path ?? "/collection/:collectionid()/doc/:docid()/detail/:pageid()",
    meta: indexwZoJAlLJIrMeta || {},
    alias: indexwZoJAlLJIrMeta?.alias || [],
    redirect: indexwZoJAlLJIrMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/detail/[pageid]/index.vue").then(m => m.default || m)
  },
  {
    name: index0tij4HpJdbMeta?.name ?? "collection-collectionid-doc-docid-edit-pageid",
    path: index0tij4HpJdbMeta?.path ?? "/collection/:collectionid()/doc/:docid()/edit/:pageid()",
    meta: index0tij4HpJdbMeta || {},
    alias: index0tij4HpJdbMeta?.alias || [],
    redirect: index0tij4HpJdbMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/edit/[pageid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRZhM61kkHkMeta?.name ?? "collection-collectionid-doc-docid-edit",
    path: indexRZhM61kkHkMeta?.path ?? "/collection/:collectionid()/doc/:docid()/edit",
    meta: indexRZhM61kkHkMeta || {},
    alias: indexRZhM61kkHkMeta?.alias || [],
    redirect: indexRZhM61kkHkMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexRbmkkavlyhMeta?.name ?? "collection-collectionid-doc-docid",
    path: indexRbmkkavlyhMeta?.path ?? "/collection/:collectionid()/doc/:docid()",
    meta: indexRbmkkavlyhMeta || {},
    alias: indexRbmkkavlyhMeta?.alias || [],
    redirect: indexRbmkkavlyhMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHES2Xt0ECbMeta?.name ?? "collection-collectionid",
    path: indexHES2Xt0ECbMeta?.path ?? "/collection/:collectionid()",
    meta: indexHES2Xt0ECbMeta || {},
    alias: indexHES2Xt0ECbMeta?.alias || [],
    redirect: indexHES2Xt0ECbMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlHQl0jdKTjMeta?.name ?? "collection",
    path: indexlHQl0jdKTjMeta?.path ?? "/collection",
    meta: indexlHQl0jdKTjMeta || {},
    alias: indexlHQl0jdKTjMeta?.alias || [],
    redirect: indexlHQl0jdKTjMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/index.vue").then(m => m.default || m)
  },
  {
    name: indexN46pAdXDa6Meta?.name ?? "data-lab",
    path: indexN46pAdXDa6Meta?.path ?? "/data-lab",
    meta: indexN46pAdXDa6Meta || {},
    alias: indexN46pAdXDa6Meta?.alias || [],
    redirect: indexN46pAdXDa6Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/data-lab/index.vue").then(m => m.default || m)
  },
  {
    name: indexQjhyC2ZwMtMeta?.name ?? "data-lab-train",
    path: indexQjhyC2ZwMtMeta?.path ?? "/data-lab/train",
    meta: indexQjhyC2ZwMtMeta || {},
    alias: indexQjhyC2ZwMtMeta?.alias || [],
    redirect: indexQjhyC2ZwMtMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/data-lab/train/index.vue").then(m => m.default || m)
  },
  {
    name: guideMXaVefcVTDMeta?.name ?? "guide",
    path: guideMXaVefcVTDMeta?.path ?? "/guide",
    meta: guideMXaVefcVTDMeta || {},
    alias: guideMXaVefcVTDMeta?.alias || [],
    redirect: guideMXaVefcVTDMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: indexvD7d4YvqU9Meta?.name ?? "index",
    path: indexvD7d4YvqU9Meta?.path ?? "/",
    meta: indexvD7d4YvqU9Meta || {},
    alias: indexvD7d4YvqU9Meta?.alias || [],
    redirect: indexvD7d4YvqU9Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUMKZ3DCoEnMeta?.name ?? "invitations-inviteid",
    path: indexUMKZ3DCoEnMeta?.path ?? "/invitations/:inviteid()",
    meta: indexUMKZ3DCoEnMeta || {},
    alias: indexUMKZ3DCoEnMeta?.alias || [],
    redirect: indexUMKZ3DCoEnMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/invitations/[inviteid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlkRNpR0fn1Meta?.name ?? "invitations",
    path: indexlkRNpR0fn1Meta?.path ?? "/invitations",
    meta: indexlkRNpR0fn1Meta || {},
    alias: indexlkRNpR0fn1Meta?.alias || [],
    redirect: indexlkRNpR0fn1Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/invitations/index.vue").then(m => m.default || m)
  },
  {
    name: jobsMcgJGwhlKlMeta?.name ?? "jobs",
    path: jobsMcgJGwhlKlMeta?.path ?? "/jobs",
    meta: jobsMcgJGwhlKlMeta || {},
    alias: jobsMcgJGwhlKlMeta?.alias || [],
    redirect: jobsMcgJGwhlKlMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: index3pafii4cj3Meta?.name ?? "learn",
    path: index3pafii4cj3Meta?.path ?? "/learn",
    meta: index3pafii4cj3Meta || {},
    alias: index3pafii4cj3Meta?.alias || [],
    redirect: index3pafii4cj3Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/learn/index.vue").then(m => m.default || m)
  },
  {
    name: loginWc5rK6qQ1vMeta?.name ?? "login",
    path: loginWc5rK6qQ1vMeta?.path ?? "/login",
    meta: loginWc5rK6qQ1vMeta || {},
    alias: loginWc5rK6qQ1vMeta?.alias || [],
    redirect: loginWc5rK6qQ1vMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexwK752oTjBtMeta?.name ?? "models-type-modelId",
    path: indexwK752oTjBtMeta?.path ?? "/models/:type()/:modelId()",
    meta: indexwK752oTjBtMeta || {},
    alias: indexwK752oTjBtMeta?.alias || [],
    redirect: indexwK752oTjBtMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/models/[type]/[modelId]/index.vue").then(m => m.default || m)
  },
  {
    name: indextq7J8ERWonMeta?.name ?? "models-type-modelId-modify",
    path: indextq7J8ERWonMeta?.path ?? "/models/:type()/:modelId()/modify",
    meta: indextq7J8ERWonMeta || {},
    alias: indextq7J8ERWonMeta?.alias || [],
    redirect: indextq7J8ERWonMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/models/[type]/[modelId]/modify/index.vue").then(m => m.default || m)
  },
  {
    name: indexV63Sgms0yUMeta?.name ?? "models",
    path: indexV63Sgms0yUMeta?.path ?? "/models",
    meta: indexV63Sgms0yUMeta || {},
    alias: indexV63Sgms0yUMeta?.alias || [],
    redirect: indexV63Sgms0yUMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhBbHSHOo2Meta?.name ?? "quality-control",
    path: indexHhBbHSHOo2Meta?.path ?? "/quality-control",
    meta: indexHhBbHSHOo2Meta || {},
    alias: indexHhBbHSHOo2Meta?.alias || [],
    redirect: indexHhBbHSHOo2Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/quality-control/index.vue").then(m => m.default || m)
  },
  {
    name: indexKBeml5z1ATMeta?.name ?? "search",
    path: indexKBeml5z1ATMeta?.path ?? "/search",
    meta: indexKBeml5z1ATMeta || {},
    alias: indexKBeml5z1ATMeta?.alias || [],
    redirect: indexKBeml5z1ATMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexpVdRqhJaYFMeta?.name ?? "share-hash",
    path: indexpVdRqhJaYFMeta?.path ?? "/share/:hash()",
    meta: indexpVdRqhJaYFMeta || {},
    alias: indexpVdRqhJaYFMeta?.alias || [],
    redirect: indexpVdRqhJaYFMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/share/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRc05IlraZTMeta?.name ?? "sites-url-about",
    path: indexRc05IlraZTMeta?.path ?? "/sites/:url()/about",
    meta: indexRc05IlraZTMeta || {},
    alias: indexRc05IlraZTMeta?.alias || [],
    redirect: indexRc05IlraZTMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexp371v0aWPEMeta?.name ?? "sites-url-browse",
    path: indexp371v0aWPEMeta?.path ?? "/sites/:url()/browse",
    meta: indexp371v0aWPEMeta || {},
    alias: indexp371v0aWPEMeta?.alias || [],
    redirect: indexp371v0aWPEMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/browse/index.vue").then(m => m.default || m)
  },
  {
    name: index3d4k6cTdLXMeta?.name ?? "sites-url-content-contentName",
    path: index3d4k6cTdLXMeta?.path ?? "/sites/:url()/content/:contentName()",
    meta: index3d4k6cTdLXMeta || {},
    alias: index3d4k6cTdLXMeta?.alias || [],
    redirect: index3d4k6cTdLXMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/content/[contentName]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUE9bxe1FVtMeta?.name ?? "sites-url-doc-docid-detail-pageid",
    path: indexUE9bxe1FVtMeta?.path ?? "/sites/:url()/doc/:docid()/detail/:pageid()",
    meta: indexUE9bxe1FVtMeta || {},
    alias: indexUE9bxe1FVtMeta?.alias || [],
    redirect: indexUE9bxe1FVtMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/detail/[pageid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa8JBJIFiX2Meta?.name ?? "sites-url-doc-docid-detail",
    path: indexa8JBJIFiX2Meta?.path ?? "/sites/:url()/doc/:docid()/detail",
    meta: indexa8JBJIFiX2Meta || {},
    alias: indexa8JBJIFiX2Meta?.alias || [],
    redirect: indexa8JBJIFiX2Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexKdPkQEzfIqMeta?.name ?? "sites-url-doc-docid",
    path: indexKdPkQEzfIqMeta?.path ?? "/sites/:url()/doc/:docid()",
    meta: indexKdPkQEzfIqMeta || {},
    alias: indexKdPkQEzfIqMeta?.alias || [],
    redirect: indexKdPkQEzfIqMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/index.vue").then(m => m.default || m)
  },
  {
    name: index12cQp5VGDhMeta?.name ?? "sites-url-imprint",
    path: index12cQp5VGDhMeta?.path ?? "/sites/:url()/imprint",
    meta: index12cQp5VGDhMeta || {},
    alias: index12cQp5VGDhMeta?.alias || [],
    redirect: index12cQp5VGDhMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/imprint/index.vue").then(m => m.default || m)
  },
  {
    name: indexxADbEotZbTMeta?.name ?? "sites-url",
    path: indexxADbEotZbTMeta?.path ?? "/sites/:url()",
    meta: indexxADbEotZbTMeta || {},
    alias: indexxADbEotZbTMeta?.alias || [],
    redirect: indexxADbEotZbTMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtTG5lT1nlMeta?.name ?? "sites-url-search",
    path: indexZtTG5lT1nlMeta?.path ?? "/sites/:url()/search",
    meta: indexZtTG5lT1nlMeta || {},
    alias: indexZtTG5lT1nlMeta?.alias || [],
    redirect: indexZtTG5lT1nlMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUZo03EhcEMeta?.name ?? "sites-url-tags",
    path: indexfUZo03EhcEMeta?.path ?? "/sites/:url()/tags",
    meta: indexfUZo03EhcEMeta || {},
    alias: indexfUZo03EhcEMeta?.alias || [],
    redirect: indexfUZo03EhcEMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexgKTCqU83lTMeta?.name ?? "sites",
    path: indexgKTCqU83lTMeta?.path ?? "/sites",
    meta: indexgKTCqU83lTMeta || {},
    alias: indexgKTCqU83lTMeta?.alias || [],
    redirect: indexgKTCqU83lTMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/index.vue").then(m => m.default || m)
  },
  {
    name: indexmVAuGbbPZ0Meta?.name ?? "tags-collectionid",
    path: indexmVAuGbbPZ0Meta?.path ?? "/tags/:collectionid()",
    meta: indexmVAuGbbPZ0Meta || {},
    alias: indexmVAuGbbPZ0Meta?.alias || [],
    redirect: indexmVAuGbbPZ0Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/tags/[collectionid]/index.vue").then(m => m.default || m)
  },
  {
    name: index4YolltvPjlMeta?.name ?? "tags",
    path: index4YolltvPjlMeta?.path ?? "/tags",
    meta: index4YolltvPjlMeta || {},
    alias: index4YolltvPjlMeta?.alias || [],
    redirect: index4YolltvPjlMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexuAQkrgui0uMeta?.name ?? "training-type-modelId",
    path: indexuAQkrgui0uMeta?.path ?? "/training/:type()/:modelId()",
    meta: indexuAQkrgui0uMeta || {},
    alias: indexuAQkrgui0uMeta?.alias || [],
    redirect: indexuAQkrgui0uMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/training/[type]/[modelId]/index.vue").then(m => m.default || m)
  },
  {
    name: index26j7tE0Iv2Meta?.name ?? "training-type-modelId-train-data",
    path: index26j7tE0Iv2Meta?.path ?? "/training/:type()/:modelId()/train-data",
    meta: index26j7tE0Iv2Meta || {},
    alias: index26j7tE0Iv2Meta?.alias || [],
    redirect: index26j7tE0Iv2Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/training/[type]/[modelId]/train-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexulyq4QWZJ4Meta?.name ?? "training-type-modelId-train-data-viewer",
    path: indexulyq4QWZJ4Meta?.path ?? "/training/:type()/:modelId()/train-data/viewer",
    meta: indexulyq4QWZJ4Meta || {},
    alias: indexulyq4QWZJ4Meta?.alias || [],
    redirect: indexulyq4QWZJ4Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/training/[type]/[modelId]/train-data/viewer/index.vue").then(m => m.default || m)
  },
  {
    name: indexXdihFnaiwnMeta?.name ?? "training-gallery",
    path: indexXdihFnaiwnMeta?.path ?? "/training/gallery",
    meta: indexXdihFnaiwnMeta || {},
    alias: indexXdihFnaiwnMeta?.alias || [],
    redirect: indexXdihFnaiwnMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/training/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexGJtUUWeFV7Meta?.name ?? "training",
    path: indexGJtUUWeFV7Meta?.path ?? "/training",
    meta: indexGJtUUWeFV7Meta || {},
    alias: indexGJtUUWeFV7Meta?.alias || [],
    redirect: indexGJtUUWeFV7Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/training/index.vue").then(m => m.default || m)
  },
  {
    name: indeximmi45XxZdMeta?.name ?? "upload-demo",
    path: indeximmi45XxZdMeta?.path ?? "/upload-demo",
    meta: indeximmi45XxZdMeta || {},
    alias: indeximmi45XxZdMeta?.alias || [],
    redirect: indeximmi45XxZdMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/upload-demo/index.vue").then(m => m.default || m)
  },
  {
    name: indexYxHtTetahJMeta?.name ?? "upload",
    path: indexYxHtTetahJMeta?.path ?? "/upload",
    meta: indexYxHtTetahJMeta || {},
    alias: indexYxHtTetahJMeta?.alias || [],
    redirect: indexYxHtTetahJMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/upload/index.vue").then(m => m.default || m)
  },
  {
    name: index2Ad79aD9acMeta?.name ?? "usage",
    path: index2Ad79aD9acMeta?.path ?? "/usage",
    meta: index2Ad79aD9acMeta || {},
    alias: index2Ad79aD9acMeta?.alias || [],
    redirect: index2Ad79aD9acMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/usage/index.vue").then(m => m.default || m)
  },
  {
    name: indexfWSuG00AO7Meta?.name ?? "usage-manage-organisation-organisationid",
    path: indexfWSuG00AO7Meta?.path ?? "/usage/manage-organisation/:organisationid()",
    meta: indexfWSuG00AO7Meta || {},
    alias: indexfWSuG00AO7Meta?.alias || [],
    redirect: indexfWSuG00AO7Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/usage/manage-organisation/[organisationid]/index.vue").then(m => m.default || m)
  },
  {
    name: index8v9ec8gGcaMeta?.name ?? "version",
    path: index8v9ec8gGcaMeta?.path ?? "/version",
    meta: index8v9ec8gGcaMeta || {},
    alias: index8v9ec8gGcaMeta?.alias || [],
    redirect: index8v9ec8gGcaMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/version/index.vue").then(m => m.default || m)
  },
  {
    name: indexhRZXvPcETFMeta?.name ?? "welcome",
    path: indexhRZXvPcETFMeta?.path ?? "/welcome",
    meta: indexhRZXvPcETFMeta || {},
    alias: indexhRZXvPcETFMeta?.alias || [],
    redirect: indexhRZXvPcETFMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubKzQrTTlmreMeta?.name ?? undefined,
    path: component_45stubKzQrTTlmreMeta?.path ?? "/home",
    meta: component_45stubKzQrTTlmreMeta || {},
    alias: component_45stubKzQrTTlmreMeta?.alias || [],
    redirect: component_45stubKzQrTTlmreMeta?.redirect,
    component: component_45stubKzQrTTlmre
  }
]