<template>
  <div
    class="app-pagination"
    :class="{ 'app-pagination--with-animation': withAnimation }"
  >
    <div class="app-pagination__main" v-if="showPerPage">
      <p class="app-pagination__label" v-if="label">{{ $t(label) }}</p>
      <select
        :value="perPage"
        class="ml-2 app-pagination__select"
        @change="updatePerPage($event.target.value)"
      >
        <option :value="itemPerPage" v-for="itemPerPage in itemsPerPage">
          {{ itemPerPage }}
        </option>
      </select>
    </div>

    <ul class="app-pagination__list">
      <li
        :class="[
          'app-pagination__item app-pagination__item--arrow',
          { 'app-pagination__item--disabled': disabledPrev },
        ]"
      >
        <BaseButton
          icon-small
          :icon="'chevron-left'"
          @click="changePage('prev')"
          id="pagination-prev"
        ></BaseButton>
      </li>
      <li class="app-pagination__item app-pagination__item--input">
        <input
          class="app-pagination__item__input"
          v-model.number="inputValue"
          type="text"
          @keydown="disallowNonNumericInput"
          id="pagination-input"
        />
      </li>
      <li
        class="app-pagination__item"
        :class="siteTheme"
        @click="changePage('last')"
      >
        {{ lastPage }}
      </li>
      <li
        :class="[
          'app-pagination__item app-pagination__item--arrow',
          { 'app-pagination__item--disabled': disabledNext },
        ]"
      >
        <BaseButton
          icon-small
          :icon="'chevron-right'"
          @click="changePage('next')"
          id="pagination-next"
        ></BaseButton>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BasePagination',
  data() {
    return {
      inputValue: '',
      minValue: 1,
      disabledNext: false,
      disabledPrev: false,
      timer: null,
    }
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    total: {
      type: Number,
      default: null,
    },
    current: {
      type: Number,
      default: null,
    },
    perPage: {
      type: Number,
      default: null,
    },
    showPerPage: {
      type: Boolean,
      default: true,
    },
    withAnimation: {
      type: Boolean,
      default: true,
    },
    itemsPerPage: {
      type: Array,
      default: () => [6, 12, 24, 48, 96],
    },
    siteTheme: {
      type: String,
      default: 'primary',
    },
    keepLastPageUntouched: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.inputValue = this.current
  },
  methods: {
    disallowNonNumericInput(event) {
      // Allow special keys: backspace, delete, arrow keys
      if (event.keyCode === 8) {
        this.inputTimer()
      }
      // Disallow non-numeric input
      else if (isNaN(event.key)) {
        event.preventDefault()
      }
      // Disallow input that is greater than the total
      else if (Number(this.inputValue + event.key) > this.lastPage) {
        event.preventDefault()
      } else {
        this.inputTimer()
      }
    },
    changePage(action) {
      switch (action) {
        case 'prev':
          this.inputValue != this.minValue && this.inputValue
            ? this.$emit('changePage', --this.inputValue)
            : null
          break

        case 'next':
          this.inputValue !== this.lastPage
            ? this.$emit('changePage', ++this.inputValue)
            : null
          break

        case 'last':
          this.$emit('changePage', this.lastPage)
          break

        default:
          break
      }
    },
    inputTimer() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.inputValue && this.$emit('changePage', this.inputValue)
      }, 1000)
    },
    updatePerPage(value) {
      this.$emit('update:perPage', parseInt(value, 10))
    },
  },
  computed: {
    lastPage() {
      if (this.showPerPage && !this.keepLastPageUntouched) {
        return Math.ceil(this.total / this.perPage)
      } else {
        return this.total
      }
    },
  },
  watch: {
    current(newVal) {
      this.inputValue = newVal
    },
  },
}
</script>
