<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog :open="open" @close="closeDialog" class="relative z-50">
      <!-- Gray Background Not sure if we want that-->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/25 transition-opacity" />
      </TransitionChild>

      <!-- Main visible Dialog -->
      <div
        class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-40"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all"
          >
            <Combobox @update:modelValue="onSelect">
              <div class="grid grid-cols-1">
                <ComboboxInput
                  class="col-start-1 row-start-1 h-12 w-full pl-11 pr-4 md:text-base sm:text-sm text-primaryG-700 outline-none placeholder:text-secondaryG-700"
                  placeholder="Search..."
                  @change="updateQuery"
                />

                <i
                  class="mdi mdi-magnify col-start-1 row-start-1 ml-4 text-xl self-center text-gray-400 pointer-events-none"
                ></i>
              </div>

              <div
                v-if="query === ''"
                class="border-t border-gray-100 px-6 py-8 text-center text-sm sm:px-14"
              >
                <p class="font-semibold text-gray-900">
                  {{ $t('Search for collections and documents') }}
                </p>
                <router-link to="/search" class="text-blue-500 hover:underline" @click="closeDialog">
                  For fulltext search go here
                </router-link>
              </div>

              <ComboboxOptions
                v-else-if="
                  !isLoadingColls &&
                  (filteredTagsColl.length > 0 || filteredDocs.length > 0)
                "
              >
                <li>
                  <h2 class="search-results__header" @click="toggleCollections">
                    <i class="mdi mdi-folder text-sm "></i>
                    {{ $t('Collections') }} ({{ filteredTagsColl.length }})
                    <i :class="showCollections ? 'mdi mdi-minus' : 'mdi mdi-plus'" class="float-right"></i>
                  </h2>
                  <ul class="search-results__list" v-show="showCollections">
                    <ComboboxOption
                      v-for="coll in filteredTagsColl"
                      :key="coll.id"
                      :value="coll"
                      as="template"
                      v-slot="{ active }"
                    >
                      <li
                        :class="[
                          'cursor-default select-none px-4 py-2',
                          active &&
                            'bg-gray-200 text-primaryG-700 outline-none',
                        ]"
                      >
                        <div class="search-results__text">
                          <strong class="search-results__title">
                            {{ coll.colName }}
                          </strong>
                          ({{ coll.colId }})
                        </div>
                        <div class="search-results__text">
                          {{ coll.nrOfDocuments }} {{ $t('Documents') }}
                        </div>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>

                <li>
                  <h2 class="search-results__header" @click="toggleDocuments">
                    <i class="mdi mdi-file text-sm"></i>
                    {{ $t('Documents') }} ({{ filteredDocs.length }})
                    <i :class="showDocuments ? 'mdi mdi-minus' : 'mdi mdi-plus'" class="float-right"></i>
                  </h2>
                  <ul class="search-results__list" v-show="showDocuments">
                    <ComboboxOption
                      v-for="doc in filteredDocs"
                      :key="doc.docId"
                      :value="doc"
                      as="template"
                      t
                      v-slot="{ active }"
                    >
                      <li
                        :class="[
                          'cursor-default select-none px-4 py-2',
                          active &&
                            'bg-gray-200 text-primaryG-700 outline-none',
                        ]"
                      >
                        <div class="search-results__text">
                          <strong class="search-results__title">
                            {{ doc.title }}
                          </strong>
                          ({{ doc.docId }})
                        </div>
                        <div class="search-results__text">
                          {{ doc.nrOfPages }} {{ $t('Pages') }}
                        </div>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>
              
              <div
              v-else-if="
                  !isLoadingColls &&
                  (filteredTagsColl.length === 0 && filteredDocs.length === 0)
                "
                class="border-t border-gray-100 px-6 py-8 text-center text-sm sm:px-14"
              >
                <p class="font-semibold text-gray-900 text-balance">
                {{$t('No collections or documents found. Did you mean to search the fulltext for')}} "{{ query }}"?
                </p>
                <router-link :to="`/search?term=${query}`" class="text-blue-500 hover:underline" @click="closeDialog">
                  Search fulltext
                </router-link>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { watch, defineEmits, defineProps } from 'vue'

import {
  Dialog,
  DialogPanel,
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

import { debounce } from '~/utils/misc.js'

const { $bus, $sentry, $router } = useNuxtApp()
const { t } = useI18n()

const collectionsApi = useCollections()
const query = ref('')
const isLoadingColls = ref(false)
const isLoadingDocuments = ref(false)
const collectionData = ref([])
const filteredTagsColl = ref([])
const documentData = ref([])
const filteredDocs = ref([])

const showCollections = ref(true)
const showDocuments = ref(true)

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['close'])
const closeDialog = () => {
  emit('close', false)
}

const toggleCollections = () => {
  showCollections.value = !showCollections.value
}

const toggleDocuments = () => {
  showDocuments.value = !showDocuments.value
}

const onSelect = value => {
  console.log('result selected', value)
  if (value.mainColId && value.docId) {
    $router.push(`/collection/${value.mainColId}/doc/${value.docId}`)
    closeDialog()
  } else if (value.colId) {
    $router.push(`/collection/${value.colId}`)
    closeDialog()
  } else {
    $bus.emit('notification', {
      message: t('Missing collection ID and document ID'),
      type: 'error',
    })
    $sentry.captureMessage(
      `Could not open search result for value: ${JSON.stringify(
        value
      )}, search term: ${query.value}`
    )
  }
}

const canSubmit = value => {
  return typeof value === 'string' && value !== '' && value.length > 2
}

const updateQuery = e => {
  query.value = e.target.value
  console.log('query', query.value)
  debouncedUpdateQuery()

  if (query.value === '') {
    filteredTagsColl.value = []
    collectionData.value = []
  }
}

const debouncedUpdateQuery = debounce(() => {
  if (canSubmit(query.value)) {
    getFilteredCollection(query.value)
    getFilteredDocuments(query.value)
  }
}, 500)

const getFilteredCollection = async query => {
  isLoadingColls.value = true
  let data
  try {
    data = await collectionsApi.filterCollections({
      offset: 0,
      limit: 100,
      filter: query,
    })

    collectionData.value = data.trpCollection.reverse()
    filteredTagsColl.value = collectionData.value
    isLoadingColls.value = false
  } catch (err) {
    isLoadingColls.value = false
    return
  }
}

const getFilteredDocuments = async query => {
  isLoadingDocuments.value = true
  let data
  try {
    data = await collectionsApi.findDocuments({
      limit: 100,
      title: query,
    })

    documentData.value = data
    filteredDocs.value = documentData.value
    isLoadingDocuments.value = false
  } catch (err) {
    isLoadingDocuments.value = false
    return
  }
}
</script>

<style scoped lang="scss">
.search-results {
  &__list {
    @apply mb-4;
  }

  &__header {
    @apply bg-gray-100 px-4 py-2 text-sm font-semibold mb-4 cursor-pointer;
  }

  &__text {
    @apply flex items-center px-2;
  }

  &__title {
    @apply max-w-[275px] overflow-hidden text-ellipsis whitespace-nowrap inline-block mr-1;
  }
}
</style>
