<template>
  <div
    class="flex items-center w-full lg:overflow-visible overflow-hidden justify-end absolute lg:relative inset-0"
    :class="{
      'overflow-visible bg-primary z-40': searchIsOpen,
    }"
  >
    <!-- search icon -->
    <i
      v-if="!searchIsOpen"
      class="mdi mdi-magnify inline-flex lg:hidden text-2xl cursor-pointer app-animation absolute right-6"
      @click="searchIsOpen = !searchIsOpen"
    ></i>
    <!-- close icon -->
    <i
      v-else
      class="mdi mdi-close inline-flex lg:hidden text-2xl cursor-pointer mx-2 app-animation"
      :class="{
        'rotate-90': searchIsOpen,
      }"
      @click="searchIsOpen = !searchIsOpen"
    ></i>
    <!-- search wrapper -->
    <div
      class="app-navbar__search__wrapper translate-x-full lg:translate-x-0 w-full max-w-[550px] opacity-100 lg:opacity-100 app-animation z-10"
      :class="{
        '!max-w-full !opacity-100 !translate-x-0 pr-4': searchIsOpen,
      }"
    >
      <!-- Full text -->
      <form
        class="app-navbar__form flex"
        method="get"
        :action="localePath({ name: 'search' })"
        @submit.prevent="submit"
        v-if="
          activeDropdownItem?.id === 0 ||
          activeDropdownItem?.id === 3 ||
          activeDropdownItem?.id === 4
        "
      >
        <BaseInput
          :placeholder="$t(activeDropdownItem.placeholder)"
          :leading="'magnify'"
          :name="'search'"
          :type="'search'"
          :id="'navbar-search-input'"
          withDropdown
          class="app-navbar__search"
          :value="tempValue"
          @input="tempValue = $event.target.value"
        />
      </form>

      <!-- Collections -->
      <FilterCollectionFilter
        class="app-navbar__form"
        v-else-if="activeDropdownItem?.id === 1"
      />

      <!-- Documents -->
      <FilterDocumentFilter
        class="app-navbar__form"
        v-else-if="activeDropdownItem?.id === 2"
      />

      <!-- Dropdown -->
      <div class="app-input__dropdown" ref="dropdown" @click="toggleDropdown">
        <label
          tabindex="0"
          class="app-input__dropdown__button !hidden sm:!flex"
        >
          <i class="mdi mdi-chevron-down app-input__dropdown__icon"></i>
          {{ activeDropdownItem?.name }}</label
        >
        <label
          tabindex="0"
          class="app-input__dropdown__button !flex sm:!hidden"
        >
          <i class="mdi mdi-chevron-down app-input__dropdown__icon"></i>
          <i :class="[`mdi mdi-${activeDropdownItem.icon}`]"></i>
        </label>
        <ul
          tabindex="0"
          class="dropdown-content app-input__dropdown__list"
          v-show="dropdownIsOpen"
        >
          <li
            class="app-input__dropdown__item"
            v-for="(item, index) in dropdownItems.filter(item => !item.active)"
            :key="index"
            @click="handleDropdown(item)"
          >
            <BaseButton
              :label="item.name"
              :type="'text--gray'"
              :icon="item.icon"
            ></BaseButton>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import omit from 'lodash/omit'

export default {
  name: 'NavbarSearch',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dropdownIsOpen: false,
    dropdownItems: [
      {
        id: 0,
        name: 'Global Text Search',
        placeholder: 'Search text across all collections',
        icon: 'text-box-search-outline',
        active: true,
      },
      {
        id: 1,
        name: 'Collections (Name)',
        placeholder: 'Search collections by title/ID',
        icon: 'folder-multiple-outline',
        active: false,
      },
      {
        id: 2,
        name: 'Documents (Name)',
        placeholder: 'Search documents by title/ID',
        icon: 'file-document-multiple-outline',
        active: false,
      },
      {
        id: 3,
        name: 'Current Collection (Text)',
        placeholder: 'Search text in current collection',
        icon: 'folder-outline',
        active: false,
      },
      {
        id: 4,
        name: 'Current Document (Text)',
        placeholder: 'Search text in current document',
        icon: 'file-document-outline',
        active: false,
      },
    ],
    initialDropdownItems: null,
    value: '',
    tempValue: '',
    searchIsOpen: false,
  }),
  watch: {
    $route: {
      handler(to) {
        let idsToRemove

        if (this.validate(to.query[this.name])) {
          this.value = this.tempValue = to.query[this.name]
        } else {
          this.$router.push({
            query: omit(this.$route.query, this.name),
          })
        }

        if (!this.initialDropdownItems) {
          this.initialDropdownItems = [...this.dropdownItems]
        } else {
          this.resetDropdownItems()
        }

        if (to.params.collectionid && to.fullPath.includes('/collection/')) {
          idsToRemove = to.params.docid ? [3] : [4]
        } else if (to.query['searchType']) {
          idsToRemove =
            to.query['searchType'] === 'collection'
              ? [4]
              : to.query['searchType'] === 'document'
              ? [3]
              : null
        } else {
          idsToRemove = [3, 4]
        }

        this.dropdownItems = this.dropdownItems.filter(
          item => !idsToRemove.includes(item.id)
        )
      },
      immediate: true,
      deep: true,
    },
    value(value) {
      let colId, docId, searchType
      const query = {}

      searchType =
        this.activeDropdownItem.id === 3
          ? 'collection'
          : this.activeDropdownItem.id === 4
          ? 'document'
          : null

      colId =
        this.activeDropdownItem.id === 3 || this.activeDropdownItem.id === 4
          ? this.$route.params.collectionid || this.$route.query['colId']
          : null

      docId =
        this.activeDropdownItem.id === 3 || this.activeDropdownItem.id === 4
          ? this.$route.params.docid || this.$route.query['docId']
          : null

      if (this.validate(value)) {
        query[this.name] = value
        searchType !== null && (query.searchType = searchType)
        colId !== null && (query.colId = colId)
        docId !== null && (query.docId = docId)

        this.$router.push({
          path: this.localePath({ path: '/search' }),
          query: query,
        })
      } else {
        query = omit(this.$route.query, this.name)

        this.$router.push({
          path: this.localePath({ path: '/search' }),
          query: query,
        })
      }
    },
  },
  methods: {
    handleDropdown(searchOption) {
      this.dropdownItems.forEach(item => {
        item.name === searchOption.name
          ? (item.active = true)
          : (item.active = false)
      })
    },
    validate(value) {
      return typeof value === 'string' && value.length > 0
    },
    submit() {
      if (this.canSubmit) {
        let submitValue = this.tempValue.trim()

        this.value = submitValue
      }
    },
    toggleDropdown() {
      if (!this.dropdownIsOpen) {
        document.addEventListener('click', this.closeDropdownOnClickOutside)
      } else {
        this.dropdownIsOpen = false
        document.removeEventListener('click', this.closeDropdownOnClickOutside)
      }
    },
    closeDropdownOnClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.dropdownIsOpen = false
        document.removeEventListener('click', this.closeDropdownOnClickOutside)
      } else {
        this.dropdownIsOpen = true
      }
    },
    resetDropdownItems() {
      if (this.initialDropdownItems) {
        this.dropdownItems = [...this.initialDropdownItems]
      }
    },
  },
  computed: {
    canSubmit() {
      return typeof this.tempValue === 'string' && this.tempValue !== ''
    },
    activeDropdownItem() {
      let activeItem = this.dropdownItems.find(item => item.active)

      if (!activeItem && !this.$route.query['searchType']) {
        let firstItem = this.dropdownItems.find(
          dropdownItem => dropdownItem.id === 0
        )
        firstItem.active = true
        activeItem = firstItem
      }

      return activeItem
    },
  },
}
</script>
