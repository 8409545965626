<template>
  <div class="app-modal__box" v-if="rsCollectionTemp">
    <div class="app-modal__header">
      <h3 class="mb-0">{{ $t('Site settings') }}</h3>
    </div>
    <BaseTabs
      @tabChange="i => (tabIndex = i)"
      :fullwidth="false"
      :tabs="
        $features.invitations
          ? [
              { title: 'General settings', id: 'tab1' },
              { title: 'Theme', id: 'tab2' },
              { title: 'Content', id: 'tab4' },
              { title: 'Report Issue ', id: 'sentry-bug' },
            ]
          : [
              { title: 'General settings', id: 'tab1' },
              { title: 'Theme', id: 'tab2' },
              { title: 'Users', id: 'tab3' },
              { title: 'Content', id: 'tab4' },
              { title: 'Report Issue ', id: 'sentry-bug' },
            ]
      "
    >
      <template #tab1>
        <h4>{{ $t('Site configuration') }}</h4>
        <BaseInput
          class="mt-5"
          :label="$t('Project title')"
          :helpText="$t('The title of your project or site')"
          :id="'title'"
          :name="'title'"
          :value="rsCollectionTemp.title"
          @input="rsCollectionTemp.title = $event.target.value"
        ></BaseInput>

        <CollectionFilter
          :restrict="true"
          :multiSelect="true"
          :newDesign="true"
          :placeholder="$t('Select a collection')"
          :label="$t('Connected collection')"
          :helpText="
            $t('Select the collection which should be shown on your site')
          "
          :initColls="rsCollectionTemp.colId"
          :isSmall="true"
          @emitColl="setSelectedColls"
          :emitSelection="true"
        />

        <BaseInput
          class="mt-5"
          :label="$t('URL')"
          :helpText="getHelpText()"
          :disabled="rsCollectionTemp?.status === 'Published'"
          :id="'url'"
          :name="'url'"
          :type="'url'"
          :value="url"
          @input="url = $event.target.value"
          :error="URLerror"
          :errorText="
            $t('Please only use letters (a-z) and numbers (0-9) for your URL')
          "
        ></BaseInput>

        <label class="app-select__label">{{ $t('Available languages') }}</label>
        <FilterLanguage
          @selected="setLanguages"
          :initFilter="languageArr"
          :customLanguage="true"
        />

        <BaseButton
          class="mt-2"
          @click="openLocalesModal"
          :icon="'earth'"
          :type="'text'"
          :label="$t('Set translation')"
        ></BaseButton>
        <hr class="my-8 border-gray opacity-50" />
        <label class="label cursor-pointer">
          <span class="">{{ $t('Enable document download') }}</span>
          <input
            :value="rsCollectionTemp.config.download"
            @input="
              rsCollectionTemp.config.download =
                !rsCollectionTemp.config.download
            "
            type="checkbox"
            class="toggle toggle-primary"
            :checked="rsCollectionTemp.config.download"
          />
        </label>
        <p class="text-gray-300 text-sm px-1">
          <b>Disclaimer:</b>
          {{
            $t(
              'You understand and agree that other users may download and access the content you upload to your Sites. It is your responsibility to ensure that you own or have obtained the appropriate licensing rights to distribute the material and that it does not infringe the rights of any third party. We accept no liability for any misuse or unauthorised distribution of the content you upload. By enabling the document download, you represent and warrant that you are in compliance with all intellectual property laws and that you will abide by our'
            )
          }}
          <a target="_blank" href="https://readcoop.org/terms" class="underline"
            >Terms and Conditions</a
          >.
        </p>

        <hr class="my-8 border-gray opacity-50" />
        <h4>{{ $t('Privacy') }}</h4>
        <div class="app-select mt-5">
          <label class="app-select__label">{{ $t('Imprint') }}</label>
          <client-only
            ><tiptap-editor
              :value="rsCollectionTemp.config.imprint"
              @inputHtml="updateContent"
              :content="rsCollectionTemp.config.imprint"
              :showSave="false"
          /></client-only>
        </div>

        <hr class="my-8 border-gray opacity-50" />
        <h4>{{ $t('Delete this site') }}</h4>
        <p>
          {{
            $t(
              'Here you can delete your site. If you delete this site, it will be instantly unpublished and all users will loose access. Be careful! This cannot be undone.'
            )
          }}
        </p>

        <BaseButton
          class="m-2"
          :label="$t('Delete this site')"
          :symbol="'delete'"
          @click="openDeleteConfirmModal()"
          :type="'danger'"
        ></BaseButton>
      </template>
      <template #tab2>
        <h4>{{ $t('Theme settings') }}</h4>
        <h5>{{ $t('Primary logo') }}</h5>
        <p>
          {{
            $t(
              'This will be the primary logo shown in the header and the footer'
            )
          }}
        </p>
        <div class="flex flex-row mt-5">
          <div class="bg-indigo-300">
            <img
              class="object-cover h-48 w-96"
              :src="getIIIFImage(rsCollection, 'logo') || noDocumentImage"
            />
          </div>
          <div class="flex flex-col w-full">
            <BaseButton
              as-label
              :for="'UploadImages'"
              class="mb-2 mx-2 mt-0"
              :label="'Upload image'"
              @click="emitUploadSlideOver('logo')"
              :type="'primary'"
            ></BaseButton>
            <BaseButton
              :disabled="!getIIIFImage(rsCollection, 'logo')"
              class="m-2"
              :label="'Remove'"
              @click="deleteImage(rsCollectionTemp, 'logo')"
              :type="'secondary'"
            ></BaseButton>
          </div>
        </div>
        <h5 class="mt-4">{{ $t('Primary color') }}</h5>
        <p>
          {{
            $t(
              'Select a custom color which will be used for the theme of the page.'
            )
          }}
        </p>
        <GraphicSelect
          :options="themes"
          :type="'colour'"
          :value="primaryThemeSelect"
          @input="primaryThemeSelect = $event.target.value"
        ></GraphicSelect>
        <h5 class="mt-4">{{ $t('Organization Link') }}</h5>
        <p>
          {{
            $t(
              'Add a custom link to your institution, organization, or external project information in the footer of your site.'
            )
          }}
        </p>
        <div class="flex flex-col">
          <BaseInput
            class="mt-5"
            :label="$t('Set your link text')"
            :id="'link-title'"
            :name="'link-title'"
            :value="rsCollectionTemp.config.orglinktitle"
            @input="rsCollectionTemp.config.orglinktitle = $event.target.value"
          ></BaseInput>
          <BaseInput
            class="mt-5"
            :label="$t('Set your link')"
            :id="'custom-link'"
            :name="'custom-link'"
            :value="rsCollectionTemp.config.orglink"
            @input="rsCollectionTemp.config.orglink = $event.target.value"
          ></BaseInput>
        </div>
      </template>
      <template #tab3>
        <UserManager
          @userChange="users => (rsCollectionTemp.additionalUsers = users)"
          @ownerChange="owner => (rsCollectionTemp.userId = owner)"
        />
      </template>
      <template #tab4>
        <h4>{{ $t('Site content') }}</h4>
        <label>
          {{ $t('Site modules') }}
        </label>

        <select
          @input="setLanguage($event.target.value)"
          class="app-select__select"
          :placeholder="'Select language'"
          style="box-shadow: none"
        >
          <option
            v-for="(lang, i) in rsCollection?.locales"
            :key="`Lang${i}`"
            :value="lang.code"
            v-bind:selected="language === lang.code"
          >
            {{ lang.label }}
          </option>
        </select>
        <hr class="my-8 border-gray opacity-50" />

        <div
          class="flex mb-4 relative"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="flex justify-center items-center">
            <input
              type="checkbox"
              class="toggle toggle-primary"
              :checked="true"
              :disabled="true"
            />
            <label class="ml-4" v-if="!showInput || editingKey !== item.key">{{
              getTranslation(item.key)
            }}</label>
          </div>
          <div
            class="flex flex-grow justify-center items-center ml-4"
            v-if="showInput && editingKey === item.key"
          >
            <BaseInput
              :value="
                rsCollectionTemp.locales.find(obj => obj.code === language)
                  .translations[item.key]
              "
              @input="handleInput($event, item.key)"
              :errorText="$t('This field cannot be empty')"
              :error="isEmpty(item.key)"
              class="mr-2"
            ></BaseInput>

            <BaseButton
              @click=";(showInput = false), (editingKey = null)"
              :icon="'close'"
              :type="'text'"
              class="mr-2"
              iconSmall
            ></BaseButton>
            <!-- prettier-ignore -->
            <BaseButton
              @click="
                showInput = false;
                editingKey = null
              "
              :icon="'check'"
              :type="'text'"
              iconSmall
            ></BaseButton>
          </div>

          <div class="button-container ml-2 absolute top-0 right-0">
            <BaseButton
              v-if="!showInput || editingKey !== item.key"
              :icon="'pen'"
              @click="startEditing(item.key)"
              :type="'text'"
              class="mr-2"
              small
            ></BaseButton>
          </div>
        </div>

        <div class="box py-2 rounded-lg border bg-sites-50">
          <div class="flex mb-4 relative">
            <div class="flex justify-center items-center">
              <input
                type="checkbox"
                class="toggle toggle-primary"
                :checked="true"
                :disabled="true"
              />
              <label class="ml-4" v-if="!showInput || editingKey !== 'about'">{{
                getTranslation('About')
              }}</label>
            </div>
            <div
              class="flex flex-grow ml-4"
              v-if="showInput && editingKey === 'about'"
            >
              <BaseInput
                :value="
                  rsCollectionTemp.locales.find(obj => obj.code === language)
                    .translations['About']
                "
                @input="
                  rsCollectionTemp.locales.find(
                    obj => obj.code === language
                  ).translations['About'] = $event.target.value
                "
                class="mr-2"
              ></BaseInput>
              <!-- prettier-ignore -->
              <BaseButton
                @click="
                  showInput = false;
                  editingKey = null
                "
                :icon="'close'"
                :type="'text'"
                class="mr-2"
                iconSmall
              ></BaseButton>
              <!-- prettier-ignore -->
              <BaseButton
                @click="
                  showInput = false;
                  editingKey = null
                "
                :icon="'check'"
                :type="'text'"
                iconSmall
              ></BaseButton>
            </div>
            <div class="button-container ml-2 absolute top-0 right-0">
              <BaseButton
                v-if="!showInput || editingKey !== 'about'"
                :icon="'pen'"
                @click="startEditing('about')"
                :type="'text'"
                class="mr-2"
                small
              ></BaseButton>
            </div>
          </div>
          <div
            class="flex mb-4 relative"
            v-for="(page, index) in rsCollectionTemp.config.additionalPages"
            :key="index"
          >
            <div class="flex justify-center items-center">
              <input
                type="checkbox"
                class="toggle toggle-primary ml-2"
                :checked="page.show"
                @change="page.show = $event.target.checked"
              />
              <label
                class="ml-4"
                v-if="!showInput || editingKey !== 'additional' + index"
                >{{ page.label }}</label
              >
            </div>
            <div
              class="flex flex-grow ml-4"
              v-if="showInput && editingKey === 'additional' + index"
            >
              <BaseInput
                :value="page.label"
                @input="pageLabel = $event.target.value"
                :errorText="$t('This field cannot be empty')"
                :error="pageLabel === ''"
                class="mr-2"
              ></BaseInput>
              <!-- prettier-ignore -->
              <BaseButton
                @click="
                  showInput = false;
                  editingKey = null
                  pageLabel = page.label
                "
                :icon="'close'"
                :type="'text'"
                class="mr-2"
                iconSmall
              ></BaseButton>
              <!-- prettier-ignore -->
              <BaseButton
                @click="
                  showInput = false;
                  editingKey = null;
                  updateContentName(page)
                "
                :disabled="page.label === ''"
                :icon="'check'"
                :type="'text'"
                iconSmall
              ></BaseButton>
            </div>
            <div class="button-container ml-2 absolute top-0 right-0">
              <BaseButton
                v-if="!showInput || editingKey !== 'additional' + index"
                :icon="'pen'"
                @click="startEditing('additional' + index)"
                :type="'text'"
                class="mr-2"
                small
              ></BaseButton>
              <BaseButton
                v-if="!showInput || editingKey !== 'additional' + index"
                :symbol="'delete'"
                @click="deleteAdditional(page.key)"
                :type="'text'"
                class="mr-2"
                small
              ></BaseButton>
            </div>
          </div>
          <BaseButton
            :label="'Add'"
            type="text"
            :icon="'plus'"
            @click="addAdditionalPage('Page-' + (additionalLength + 1))"
          ></BaseButton>
        </div>
      </template>
    </BaseTabs>
    <div
      v-if="!($features.invitations && tabIndex === 2)"
      class="sticky-SitesSettingsFooter flex flex-row justify-end bg-white border-t border-gray-200 -ml-3 -mr-3 z-40"
    >
      <BaseButton
        as-label
        class="m-3"
        :type="'primary'"
        :icon="'content-save'"
        :label="'Save'"
        @click="saveChanges()"
      ></BaseButton>
    </div>
  </div>
</template>

<script>
import noDocumentImage from '~/assets/images/no-document.png'
import GraphicSelect from '~/components/base/SelectWithGraphic.vue'

import TiptapEditor from '~/components/Sites/TipTapEditor.vue'
import DeleteSitesModal from '~/components/Sites/DeleteSitesCollection.vue'
import LocalesModal from '~/components/Sites/LocalesModal.vue'
import UploadImages from '~/components/Sites/UploadImages.vue'
import UserManager from '~/components/Sites/UserManager.vue'

import CollectionFilter from '~/components/Filter/MultiCollectionFilter.vue'

import enJson from '~/locales/sites/en.json'
import deJson from '~/locales/sites/deu.json'

import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'

const sitesApi = useSites()

import languageData from '~/assets/language-codes.json'
export default {
  components: {
    CollectionFilter,
    TiptapEditor,
    DeleteSitesModal,
    LocalesModal,
    UploadImages,
    UserManager,
    GraphicSelect,
  },
  setup() {
    const { userProfile } = useKeycloak()
    const store = useSiteStore()

    return {
      userProfile,
      store,
      noDocumentImage,
    }
  },
  data() {
    return {
      title: '',
      aboutText: '',
      description: '',
      selectedColls: null,
      rsCollectionTemp: null,
      metaData: null,
      url: '',
      pageLabel: '',
      showInput: false,
      hovered: null,
      existingRS: null,
      tags: [],
      config: { imprint: {} },
      URLerror: false,
      primaryThemeSelect: '',
      editingKey: null,
      errors: {},
      themes: [
        {
          name: 'Original',
          colour: '#4D2F42',
          value: 'themeoriginal',
        },
        {
          name: 'Wolpi',
          colour: '#193060',
          value: 'themewolpi',
        },
        {
          name: 'Autumnal',
          colour: '#BA5100',
          value: 'themeautumnal',
        },
        {
          name: 'Forest',
          colour: '#235A0A',
          value: 'themeforest',
        },
        { name: 'Lagoon', colour: '#195f50', value: 'themelagoon' },
        { name: 'Rose', colour: '#AB467C', value: 'themerose' },
      ],
    }
  },
  watch: {
    'rsCollectionTemp.url'(value) {
      if (value) {
        if (this.isURLvalid(value)) {
          this.URLerror = true
        } else {
          this.URLerror = false
        }
      }
    },
    collId: {
      handler(value) {
        if (value) {
          // this.load()
        }
      },
    },
    primaryThemeSelect: {
      handler(val) {
        this.setTheme(val)
      },
    },
    rsCollectionTemp: {
      handler(val) {
        this.store.update({
          rsCollection: JSON.parse(JSON.stringify(val)),
        })
        val = {}
      },
      deep: true,
    },
  },
  created() {
    this.$bus.on('loadRS', this.redirectSites)
    this.$bus.on('saveLocales', this.saveLocales)
  },
  destroyed() {
    this.$bus.off('loadRS', this.redirectSites)
    this.$bus.off('saveLocales', this.saveLocales)
  },
  computed: {
    ...mapState(useSiteStore, ['rsCollection', 'language', 'unsavedChanges']),
    collId() {
      return parseInt(this.$route.params.collectionid)
    },
    languageArr() {
      return this.rsCollection?.locales?.map(lang => ({
        English: lang.label,
        alpha2: lang.code,
      }))
    },
    items() {
      const language = 'en' // Set the desired language code
      return [
        { key: 'Home', translation: this.getTranslation('Home') },
        { key: 'Search', translation: this.getTranslation('Search') },
        { key: 'Explore', translation: this.getTranslation('Explore') },

        { key: 'Content', translation: this.getTranslation('Content') },
      ]
    },
    additionalLength() {
      return (
        parseInt(this.rsCollectionTemp?.config?.additionalPages?.length) || 0
      )
    },
    colorValue() {
      if (this.rsCollectionTemp?.config?.color) {
        return this.rsCollectionTemp.config.color
      } else {
        return '#4D2F42'
      }
    },
    currentLocale() {
      let lang = this.$i18n.locales
        .filter(i => i.code === this.$i18n.locale)
        .pop()

      if (!languageData[0][lang?.name]) {
        lang = { code: 'en', iso: 'en', name: 'English', label: 'EN' }
      }
      return lang
    },
    userId() {
      return this.userProfile?.UserId
    },
  },
  async mounted() {
    this.rsCollectionTemp = JSON.parse(JSON.stringify(this.rsCollection))
    this.url = this.rsCollectionTemp.url
    await this.getCollectionMeta()
    this.selectedColls = {
      colId: this.rsCollectionTemp.colId,
      colName: this.metaData?.colName,
    }
    try {
      this.$sentry.setUser({
        email: this.userProfile.Email,
      })
      const feedback = this.$sentry.feedbackIntegration({
        autoInject: false,
        colorScheme: 'light',
        showBranding: false,
        showName: false,
        showEmail: false,
        themeLight: {
          submitBackground: '#193060',
          submitBackgroundHover: '#101e3d',
          inputOutlineFocus: '2px solid #193060',
          boxShadow: '0 0 1px rgba(0, 0, 0, 0.1)',
        },
      })

      feedback.attachTo(document.querySelector('#sentry-bug'), {
        formTitle: this.$t('We are here to fix it!'),
        submitButtonLabel: this.$t('Send Report'),
        messageLabel: this.$t('Describe the issue'),
        messagePlaceholder: this.$t('What went wrong? What did you expect?'),
        cancelButtonLabel: this.$t('Cancel'),
        successMessageText: this.$t(
          'Your report helps us identify issues and improve overall performance. We appreciate your input!'
        ),
      })
    } catch (err) {
      console.error(err)
    }
  },
  methods: {
    isURLvalid(url) {
      const allowedPattern = /^[a-zA-Z0-9\-_\.]+$/

      // Maximum length for the url
      const maxLength = 100

      if (url.length > maxLength) {
        return true
      }
      if (!allowedPattern.test(url)) {
        return true
      }
      return false
    },
    isHovered(value) {
      this.hovered = value
    },
    updateContent(newContent) {
      this.rsCollectionTemp.config.imprint = newContent
    },
    getHelpText() {
      if (this.rsCollectionTemp?.status !== 'Published') {
        const config = useRuntimeConfig()
        return (
          config.public.REDIRECT_URI + '/sites/' + this.rsCollectionTemp?.url
        )
      } else {
        return this.$t('URL can only be changed before publishing')
      }
    },
    setLanguage(value) {
      if (value) {
        this.store.setLanguage(value)
      }
    },
    handleInput(event, key) {
      const value = event.target.value.trim()
      const locale = this.rsCollectionTemp.locales.find(
        obj => obj.code === this.language
      )

      if (locale) {
        locale.translations[key] = value
        console.log('🚀 ~ handleInput ~ value:', value)
        // Validate the input
        if (!value) {
          this.errors[key] = true
        } else {
          delete this.errors[key]
        }
      }
    },
    isEmpty(key) {
      console.log('🚀 ~ isEmpty ~ this.errors:', this.errors)
      return this.errors[key] || false
    },
    async getCollectionMeta() {
      let data
      try {
        data = await sitesApi.fetchCollectionMeta({
          collId: this.rsCollectionTemp.colId,
        })
      } catch (err) {}
      this.metaData = data
    },
    setSelectedColls(value) {
      this.selectedColls = []
      for (let i in value) {
        let coll = value[i]

        this.selectedColls.push(coll.colId)
      }
      this.rsCollectionTemp.colId = this.selectedColls
    },
    getIIIFImage(item, type) {
      if (item.images && item.images.find(i => i.type === type)) {
        let image = item.images.find(image => image.type === type)
        if (image) {
          return (
            'https://s3.readcoop.eu/api-sites-test/' +
            image.name +
            '.' +
            image.extension
          )
        }
      }
      return null
    },
    async deleteImage(item, type) {
      const foundImage = item.images.find(i => i.type === type)

      try {
        let data = await sitesApi.deleteReadSearchImage({
          fileName: foundImage.name + '.' + foundImage.extension,
          customUrl: this.rsCollectionTemp.url,
        })
        const index = item.images.findIndex(i => i.type === type)
        if (index !== -1) {
          item.images.splice(index, 1)
        }
        this.store.update({
          rsCollection: JSON.parse(JSON.stringify(this.rsCollectionTemp)),
        })
        const notification = {
          message: this.$t('Deleted image'),
          type: 'success',
        }
        this.$bus.emit('notification', notification)
        // this.$forceUpdate()
        // window.location.reload(true)
      } catch (err) {
        const notification = {
          message: this.$t('Image deletion failed'),
          type: 'danger',
        }
        this.$bus.emit('notification', notification)
      }
    },
    deselectColl() {
      this.selectedColls = null
    },
    emitUploadSlideOver(type, section) {
      if (type && section) {
        type = type.replace(/\s+/g, '_')

        section.image = type
      }
      const slideOver = {
        slideOver: 'UploadImages',
        slideContent: {
          component: UploadImages,
          props: {
            locales: this.rsCollectionTemp.locales,
            fileName: type,
          },
          data: {},
        },
      }
      this.$bus.emit('slideOver', slideOver)
    },
    startEditing(key) {
      this.showInput = true
      this.editingKey = key
    },
    setTags(value) {
      this.tags = value
    },
    saveLocales(locales) {
      this.rsCollectionTemp.locales = locales
    },
    getTranslation(key) {
      const locale = this.rsCollectionTemp.locales.find(
        obj => obj.code === this.language
      )
      return locale.translations[key] ? locale.translations[key] : key
    },
    addAdditionalPage(value) {
      if (!this.rsCollectionTemp.config.additionalPages) {
        this.rsCollectionTemp.config.additionalPages = []
      }
      this.rsCollectionTemp.config.additionalPages.push({
        key: value,
        show: false,
        label: value,
      })

      for (let i = 0; i < this.rsCollectionTemp.locales.length; i++) {
        if (!this.rsCollectionTemp.locales[i].translations.additionalPages) {
          this.rsCollectionTemp.locales[i].translations.additionalPages = {}
        }
        this.rsCollectionTemp.locales[i].translations.additionalPages[value] =
          enJson.about
      }
    },
    deleteAdditional(key) {
      const index = this.rsCollectionTemp.config.additionalPages.findIndex(
        page => page.key === key
      )
      if (index !== -1) {
        this.rsCollectionTemp.config.additionalPages.splice(index, 1)
        for (let i = 0; i < this.rsCollectionTemp.locales.length; i++) {
          const translations = this.rsCollectionTemp.locales[i].translations
          if (
            translations.additionalPages &&
            translations.additionalPages[key]
          ) {
            delete translations.additionalPages[key]
          }
        }
      }
    },
    updateContentName(page) {
      const key = page.key
      for (let i = 0; i < this.rsCollectionTemp.locales.length; i++) {
        const currentLocale = this.rsCollectionTemp.locales[i]
        const currentTranslations =
          currentLocale.translations.additionalPages[key]
        currentLocale.translations.additionalPages[key] = {
          ...currentTranslations,
        }
      }
      page.label = this.pageLabel
    },
    openLocalesModal() {
      this.modal = {
        modalContent: {
          component: LocalesModal,
          props: {
            locales: this.rsCollectionTemp.locales,
          },
        },
      }
      let props = {
        isFullscreen: true,
      }
      this.$bus.emit('open-modal', { modal: this.modal, props: props })
    },
    openDeleteConfirmModal() {
      this.modal = {
        modalContent: {
          component: DeleteSitesModal,
          props: { item: this.rsCollection },
        },
      }
      this.$bus.emit('open-modal', { modal: this.modal })
    },
    setColor(value) {
      this.rsCollectionTemp.config.color = value
      this.store.update({
        rsCollection: JSON.parse(JSON.stringify(this.rsCollectionTemp)),
      })
    },
    setTheme(value) {
      this.rsCollectionTemp.config.theme = value
      this.store.update({
        rsCollection: JSON.parse(JSON.stringify(this.rsCollectionTemp)),
      })
    },
    setLanguages(value) {
      if (!value) {
        return
      }
      this.store.setLanguage(value[0].alpha2)

      if (value) {
        const updatedCollection = JSON.parse(
          JSON.stringify(this.rsCollectionTemp)
        )

        // Remove languages that are not in the updated value
        updatedCollection.locales = updatedCollection.locales.filter(lang =>
          value.some(l => l.alpha2 === lang.code)
        )

        const languagesLeft = updatedCollection.locales.map(lang => lang.code)

        if (languagesLeft.length === 0) {
          updatedCollection.locales = []
        }

        value.forEach(lang => {
          if (!updatedCollection.locales.some(l => l.code === lang.alpha2)) {
            updatedCollection.locales.push({
              label: lang[this.currentLocale.name],
              code: lang.alpha2,
              translations: this.getJSON(lang.alpha2),
            })
          }
        })

        // Check if rsCollectionTemp is already equal to prevent infinite loop
        if (
          JSON.stringify(this.rsCollectionTemp) ===
          JSON.stringify(updatedCollection)
        ) {
          return
        }

        this.rsCollectionTemp = updatedCollection
      }
    },
    async saveChanges() {
      try {
        if (
          this.rsCollectionTemp.config.orglink &&
          !this.validHTTPLink(this.rsCollectionTemp.config.orglink)
        ) {
          this.rsCollectionTemp.config.orglink =
            'https://' + this.rsCollectionTemp.config.orglink
        }
        if (this.rsCollectionTemp.url !== this.url) {
          // TODO: check if url is already taken
          let isTaken = await sitesApi.urlTaken({ url: this.url })
          if (isTaken) {
            const notification = {
              message: this.$t('URL is already taken'),
              type: 'danger',
              position: 'center',
            }
            this.$bus.emit('notification', notification)
            return
          } else {
            let tempUrl = this.rsCollectionTemp.url
            this.rsCollectionTemp.url = this.url
            let data = await sitesApi.updateRsLiteCollection({
              sites: this.rsCollectionTemp,
              customUrl: tempUrl,
            })
            this.$router.push(this.localePath(`/sites/${this.url}`))
          }
        } else {
          let data = await sitesApi.updateRsLiteCollection({
            sites: this.rsCollectionTemp,
            customUrl: this.rsCollectionTemp.url,
          })
          const notification = {
            message: this.$t('Updated Sites'),
            type: 'success',
            position: 'center',
          }
          this.$bus.emit('notification', notification)
        }
        this.store.setSites(this.rsCollectionTemp)
      } catch (err) {
        const notification = {
          message: this.$t('Updating Sites failed'),
          type: 'danger',
          position: 'center',
        }
        this.$bus.emit('notification', notification)
      }
    },

    // saveChanges() {
    //   this.$bus.emit('saveChanges')
    // },
    redirectSites() {
      this.$router.push(this.localePath('/sites'))
    },
    getJSON(languageCode) {
      //TODO : if selected language as additonalPages copy them to new language

      const langCode = languageCode.toLowerCase().substring(0, 2)
      const selectedLang = this.rsCollection.locales.find(
        obj => obj.code == this.language
      )
      let lang
      switch (langCode) {
        case 'de':
          lang = deJson
          lang.additionalPages = selectedLang.translations.additionalPages
          return lang

        default:
          lang = enJson
          lang.additionalPages = selectedLang.translations.additionalPages
          return lang
      }
    },
    validHTTPLink(link) {
      try {
        new URL(link)
        console.log('oui')
        return true
      } catch (err) {
        console.log('non')
        return false
      }
    },
  },
}
</script>

<style>
.sticky-SitesSettingsHeader {
  position: sticky !important;
  top: 0rem !important;
}

.sticky-SitesSettingsFooter {
  position: sticky !important;
  bottom: 0rem !important;
}
</style>
