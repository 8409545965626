import { useFetch, useNuxtApp } from 'nuxt/app'

type FetchOptions = {
  baseURL: string
  headers: {
    Authorization?: string
    'Content-Type'?: string
    Accept?: string
  }
  params?: any
  body?: Object
}

export function useApi() {
  const config = useRuntimeConfig()
  const { $bus, $sentry, $features } = useNuxtApp()

  async function fetchWithAuth<T>(
    url: string,
    request: 'GET' | 'POST' | 'DELETE' | 'PUT',
    options?: {
      data?: Object
      providedHeaders?: Object
      providedOptions?: Object
      defaultOptions?: Object
    }
  ) {
    const keycloakStore = useKeycloak()

    return await fetch<T>(url, request, options, keycloakStore.keycloak?.token)
  }

  async function fetch<T>(
    url: string,
    request: 'GET' | 'POST' | 'DELETE' | 'PUT',
    options?: {
      data?: Object
      providedHeaders?: Object
      providedOptions?: Object
      defaultOptions?: Object
    },
    bearerToken?: string
  ) {
    let fetchOptions: FetchOptions = {
      baseURL: config.public.apiKey,
      headers: {
        ...options?.providedHeaders,
        ...(bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {}),
        Accept: 'application/json, text/plain, */*',
      },
      params: {
        ...options?.defaultOptions,
        ...options?.providedOptions,
      },
    }

    if (['POST', 'PUT', 'DELETE'].includes(request)) {
      fetchOptions.body = options?.data
    }

    try {
      const response = await $fetch<T>(url, {
        method: request,
        ...fetchOptions,
      })
      return response
    } catch (error: any) {
      const statusCode = error?.response?.status || error?.statusCode

      if (
        $features?.refreshTokenForSave &&
        statusCode === 403 &&
        url.includes('/text')
      ) {
        const keycloakStore = useKeycloak()

        console.log('Refresh token after saving XML failed:')

        try {
          console.log('Attempting to refresh token...')
          const refreshed = await keycloakStore.keycloak.updateToken(30) // Refresh token if it will expire in 30 seconds
          if (refreshed) {
            console.log('Token refreshed successfully')
            keycloakStore.setLogined(true)
          } else {
            console.log('Token is still valid')
          }
          const response = await $fetch<T>(url, {
            method: request,
            ...fetchOptions,
          })
          return response
        } catch (error) {
          console.log(
            'Failed to refresh the token, or the session has expired',
            error
          )
          keycloakStore.keycloak.logout() // Redirect to login page if token refresh fails
        }
      }

      // Emit a notification for user-friendly feedback
      if (statusCode >= 500) {
        $bus.emit('notification', {
          message: 'Internal Server Error. Please try again later.',
          type: 'error',
        })
      }

      // else if (statusCode >= 400) {
      //   $bus.emit('notification', {
      //     message: `An error occurred: ${statusCode}`,
      //     type: 'error',
      //   });
      // } else {
      //   $bus.emit('notification', {
      //     message: 'Failed to fetch. Please try again later.',
      //     type: 'error',
      //   });
      // }

      // Capture the error in Sentry with `http.status_code`
      $sentry.captureException(error, {
        contexts: {
          http: {
            url,
            method: request,
            status_code: statusCode?.toString(),
          },
        },
      })

      // Re-throw or handle error
      throw createError({
        statusCode,
        statusMessage: error.message || 'An unknown error occurred.',
        data: error?.response?.data,
      })
    }
  }

  return { fetchWithAuth, fetch }
}
