import { showError } from "nuxt/app"

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
      if (error.statusCode === 403) {
        showError(error)
      } else {
        // Handle other errors
      }
    }
  })
  